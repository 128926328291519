import globalActions from "../api/GlobalActions.js"
import globalMutations from "../api/GlobalMutations.js"
import globalGetters from "../api/GlobalGetters.js"

export const state = () => ({
  isStale: true,
  latest_fetch: "",
  type: "farmunits",
  pageLayout: "StructureTable",
  canEdit: false,
  canView: false,
  effectedByYear: false,
  yearFields: [],
  aliasField: "unit_id",
  modelLabels: {
    single: "Farmunit",
    plural: "Farmunits"
  },
  filterHideOverrides: [
    "audits",
    "ownershipHistory",
    "producerHistory",
    "orders",
    "diversionPoints"
  ],
  sums: ["irrigated_acres"],
  relations: [
    {
      name: "audits",
      translate: "audits",
      label: "Audits",
      value: "audits",
      storename: "audits",
      yearFields: [],
      export: false
    },
    {
      name: "district",
      translate: "district",
      label: "District",
      value: "districts",
      storename: "districts",
      yearFields: [],
      export: true
    },
    {
      name: "owners",
      translate: "owners",
      label: "Owners",
      value: "contacts",
      storename: "contacts",
      yearFields: [],
      export: true
    },
    {
      name: "producer",
      translate: "producer",
      label: "Producer",
      value: "contacts",
      storename: "contacts",
      yearFields: [],
      export: true
    },
    // {
    //   name: "secondary",
    //   translate: "secondary",
    //   value: "secondary",
    //   storename: "contacts",
    //   yearFields: [],
    //   export: true
    // },
    // {
    //   name: "voter",
    //   translate: "voter",
    //   value: "contacts",
    //   storename: "contacts",
    //   yearFields: []
    // },
    {
      name: "canals",
      translate: "canals",
      label: "Canals",
      value: "canals",
      storename: "canals",
      yearFields: [],
      export: false
    },
    // {
    //   name: "irrigationType",
    //   translate: "irrigationType",
    //   label: "Irrigation",
    //   value: "irrigationTypes",
    //   storename: "irrigationTypes",
    //   yearFields: [],
    //   export: true
    // },
    {
      name: "orders",
      translate: "orders",
      label: "Orders",
      value: "orders",
      storename: "orders",
      yearFields: [
        /* "run_date" */
      ]
    },
    // {
    //   name: "usages",
    //   translate: "usages",
    //   value: "usages",
    //   storename: "usages",
    //   yearFields: [/* "run_date" */]
    // },
    {
      name: "diversionPoints",
      translate: "diversionPoints",
      label: "T/O",
      value: "diversionPoints",
      storename: "diversionPoints",
      yearFields: [],
      export: true
    },
    {
      name: "ownershipHistory",
      translate: "ownershipHistory",
      label: "Ownership History",
      value: "ownershipHistory",
      storename: "ownershipHistory",
      yearFields: []
    },
    {
      name: "producerHistory",
      translate: "producerHistory",
      label: "Producer History",
      value: "producerHistory",
      storename: "producerHistory",
      yearFields: []
    }
  ],
  all: {},
  reference: {},
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 50,
    sort: false,
    sortBy: "district.id",
    page: 1,
    sortDesc: []
  },
  total: 0,
  print: false,
  print_path: "",
  export: false,
  bound: {
    county: "PONDERA",
    irrigationType: 1
  },
  search: null,
  searchFields: ["unit_id"],
  stub: {
    audits: [],
    omsettings: [],
    owners: [],
    district: {},
    canals: [],
    producer: {},
    secondary: [],
    diversionPoints: [],
    usages: [],
    ownershipHistory: [],
    producerHistory: []
  },
  filter: {},
  quickFilters: [],
  include: "orders,owners,producer,diversionPoints,canals,audits",
  headers: {},
  notifications: {}
})

// Mutations ==================

export const mutations = {
  ...globalMutations
}

// Actions ====================

export const actions = {
  ...globalActions
}

// Getters ====================

export const getters = {
  ...globalGetters
}
