import moment from "moment"
import globalActions from "../api/GlobalActions.js"
import globalMutations from "../api/GlobalMutations.js"
import globalGetters from "../api/GlobalGetters.js"

export const state = () => ({
  isStale: true,
  latest_fetch: "",
  type: "contacts",
  modelLabels: {
    single: "Contact",
    plural: "Contacts"
  },
  pageLayout: "StructureTable",
  canEdit: false,
  canView: false,
  effectedByYear: false,
  yearFields: [],
  filterHideOverrides: ["audits", "notes", "producesFor", "addressHistory", "ownsDp", "ownsOrders", "producesOrders"],
  aliasField: "full_name",
  relations: [
    {
      name: "audits",
      translate: "audits",
      label: "Audits",
      value: "audits",
      storename: "audits",
      yearFields: [],
      export: false
    },
    {
      name: "ownsFu",
      label: "Owner: Farmunit",
      compactLabel: "Farmunit",
      translate: "farmowner",
      value: "farmunits",
      storename: "farmunits",
      yearFields: [],
      export: true,
      filterOnAttributes: true
    },
    {
      name: "producesFu",
      translate: "farmproducer",
      label: "Producer: Farmunit",
      compactLabel: "Farmunit",
      value: "farmunits",
      storename: "farmunits",
      yearFields: [],
      export: true,
      filterOnAttributes: true
    },
    {
      name: "flags",
      translate: "flags",
      label: "Flag",
      value: "flags",
      storename: "flags",
      yearFields: []
    },
    {
      name: "notes",
      translate: "notes",
      label: "Note",
      value: "notes",
      storename: "notes",
      yearFields: []
    },
    {
      name: "ownsDp",
      translate: "diversionPoints",
      label: "Owns: Turnouts",
      compactLabel: "Turnouts",
      value: "diversionPoints",
      storename: "diversionPoints",
      yearFields: [],
      filterOnAttributes: true
    },
    {
      name: "producesDp",
      translate: "diversionPoints",
      label: "Producing On: Turnouts",
      compactLabel: "Turnouts",
      value: "diversionPoints",
      storename: "diversionPoints",
      yearFields: []
    },
    {
      name: "addressHistory",
      translate: "addressHistory",
      label: "Address History",
      value: "addressHistory",
      storename: "addressHistory",
      yearFields: []
    },
    {
      name: "ownsOrders",
      translate: "orders",
      label: "Orders on Owned Turnouts",
      compactLabel: "Orders",
      value: "orders",
      storename: "orders",
      yearFields: [],
      filterOnAttributes: true
    },
    {
      name: "producesOrders",
      translate: "orders",
      label: "Orders on Producing Turnouts",
      compactLabel: "Orders",
      value: "orders",
      storename: "orders",
      yearFields: [],
      filterOnAttributes: true
    },
    {
      name: "producesFor",
      translate: "contacts",
      label: "Produces For",
      value: "contacts",
      storename: "contacts",
      yearFields: []
    }
  ],
  all: {},
  reference: {
    /* for showing paginated resources ... needs a bit more work. */
  },
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: "full_name",
    page: 1,
    sortDesc: []
  },
  print_path: "",
  total: 0,
  print: false,
  export: false,
  search: null,
  searchFields: ["full_name", "company", "name"],
  bound: {
    // full_name: "",
    // company: "",
    // first_name: "",
    // last_name: "",
    // address: "",
    // address_1: "",
    // city: "",
    // state: "",
    // zip: "",
    // cell: "",
    // email: "",
    // phone: "",
    // owns: [],
    // produces: [],
    // secondary: [],
    // flags: [],
    // isOwner: "",
    // isProducer: "",
  },
  stub: {
    audits: [],
    notes: [],
    ownsFu: [],
    producesFu: [],
    flags: [],
    addressHistory: [],
    diversionPoints: []
  },
  filter: {},
  quickFilters: [
    {
      label: "Water Balance",
      name: "waterBalance",
      order: 1,
      value: [
        {
          label: "Less than 30% remaining",
          desc: "A list of contacts that have less than 30% of their water balance remaining",
          order: 1,
          schema: { remaining_allotment: "ltFloat(30)", producesDp: "arrHas(1)" }
        },
        {
          label: "Less than 20% remaining",
          desc: "A list of contacts that have less than 20% of their water balance remaining",
          order: 2,
          schema: { remaining_allotment: "ltFloat(20)", producesDp: "arrHas(1)" }
        },
        {
          label: "Less than 10% remaining",
          desc: "A list of contacts that have less than 10% of their water balance remaining",
          order: 3,
          schema: { remaining_allotment: "ltFloat(10)", producesDp: "arrHas(1)" }
        },
        {
          label: "Less than 90% remaining",
          desc: "A list of contacts that have less than 90% of their water balance remaining",
          order: 4,
          schema: { remaining_allotment: "ltFloat(90)", producesDp: "arrHas(1)" }
        }
      ]
    },
    {
      label: "Orders",
      name: "orders", // do we need this?
      order: 2,
      value: [
        {
          label: "Has Active Orders",
          desc: "A list of contacts that have at least one currently running order",
          order: 1,
          schema: {
            "producesDp.orders.on_date": `lt(${moment().format("YYYY-MM-DD")})`,
            "producesDp.orders.off_date": "any(false)"
          }
        },
        {
          label: "Has Scheduled Orders",
          desc: "A list of contacts that have at least one order scheduled to run in the future",
          order: 2,
          schema: {
            "producesDp.orders.on_requested_date": `gt(${moment().format(
              "YYYY-MM-DD"
            )})`
          }
        },
        {
          label: "Has Scheduled Stop",
          desc: "A list of contacts that have at least one order scheduled to be turned off in the future",
          order: 3,
          schema: {
            "producesDp.orders.off_requested_date": `gt(${moment().format(
              "YYYY-MM-DD"
            )})`
          }
        }
      ]
    },
    {
      label: "Producers by District",
      component: "SearchProducersByDistrict",
      name: "districts", // do we need this?
      relationName: "producesDp.district",
      field: "name",
      description: "Find producers by one or more districts",
      order: 3
    },
    {
      label: "Owner by District",
      component: "SearchProducersByDistrict",
      name: "districts", // do we need this?
      relationName: "ownsFu.district",
      field: "name",
      description: "Find land owners by one or more districts",
      order: 4
    }
  ],

  // members are called contractors on the server
  include: "flags,notes",
  headers: {},
  notifications: {}
})

export const mutations = {
  ...globalMutations
}

export const actions = {
  ...globalActions,
  owners ({ state, commit, dispatch }, customParams) {
    const filter = {
      ...state.filter,
      isOwner: true,
      isProducer: ""
    }

    commit("replaceFilter", filter)
    return dispatch("list", customParams)
  },
  producers ({ commit, dispatch }, customParams) {
    const filter = {
      ...state.filter,
      isOwner: "",
      isProducer: true
    }

    commit("replaceFilter", filter)
    return dispatch("list", customParams)
  }
}

// Getters ====================

export const getters = {
  ...globalGetters
}
