export const state = () => ({
  content: "",
  color: ""
})
export const actions = {
  showMessage ({ commit }, payload) {
    commit("showMessage", payload)
  }
}

export const mutations = {
  showMessage (state, payload) {
    state.content = payload.content
    state.color = payload.color
  }
}
