export default async function ({ $auth, store, $repositories }) {
  if ($auth.$state.user) {
    if (JSON.stringify(store.state.omsettings.all) === "{}") {
      await store.dispatch("omsettings/list")
    }

    if (!store.state.system.availableWaterYears.length) {
      const result = await $repositories.getWaterYears.index()
      if (result.hasOwnProperty("error")) {
        return
      }
      store.commit("system/SET_ALL_WATER_YEARS", result)
    }

    let waterYear = store.state.system.waterYear

    if (!waterYear) {
      const newOrder = JSON.parse(
        JSON.stringify(store.state.system.availableWaterYears)
      )
      newOrder.sort()
      newOrder.reverse()
      waterYear = newOrder[0]
    }

    await store.dispatch("system/setWaterYear", waterYear)
  }
}
