import { normalize } from "normalizr"
import globalActions from "../api/GlobalActions.js"
import globalMutations from "../api/GlobalMutations.js"
import globalGetters from "../api/GlobalGetters.js"

export const state = () => ({
  type: "columnPresets",
  pageLayout: "StructureTable",
  canEdit: false,
  canView: false,
  effectedByYear: true,
  yearFields: [],
  aliasField: "name",
  relations: [],
  all: {
    1: {
      id: 1,
      group: "default",
      path: "farmunits",
      name: "Farm Unit Standard Export",
      columns: [
        "unit_id",
        "owners.full_name",
        "producer.full_name",
        "district.name",
        "diversionPoints.canal",
        "diversionPoints.name",
        "diversionPoints.irrigationType",
        "diversionPoints.irrigationType",
        "irrigated_acres"
      ],
      order_in_group: 1,
      order_in_path: 1
    }
  },
  reference: {
    /* for showing paginated resources ... needs a bit more work. */
  },
  modelLabels: {
    single: "Column Preset",
    plural: "Column Presets"
  },
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: "name",
    page: 1,
    sortDesc: []
  },
  total: 0,
  print: false,
  export: false,
  print_path: "",
  stub: {},

  bound: {},
  filter: {},
  include: "",
  headers: {},
  notifications: {}
})

// Mutations ==================

export const mutations = {
  REPLACE_ALL () {

  },
  replaceFilter () {

  },
  ...globalMutations
}

// Actions ====================

export const actions = {}

// Getters ====================

export const getters = {
  ...globalGetters,
  lastUpdated (state, getters) {
    const all = Object.values(state.all ?? [])

    if (all.length) {
      return all[0].last_updated_at
    }
    return ""
  },
  getByPath: state => (path) => {
    const filtered = []
    Object.keys(state.all).forEach((presetId) => {
      if (state.all[presetId].path === path) {
        filtered.push(state.all[presetId])
      }
    })
    return filtered
  },
  getByGroup: state => (group) => {
    const filtered = []
    Object.keys(state.all).forEach((presetId) => {
      if (state.all[presetId].group === group) {
        filtered.push(state.all[presetId])
      }
    })
    return filtered
  }
}
