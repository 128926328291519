import Vue from "vue"
import moment from "moment"

Vue.filter("formatPhone", function (v) {
  if (!v) {
    return ""
  }
  const s2 = ("" + v).replace(/\D/g, "")
  const m = s2.match(/^(\d{3})(\d{3})(\d{4})$/)
  return !m ? null : "(" + m[1] + ") " + m[2] + "-" + m[3]
})

Vue.filter("formatUnixDate", function (v) {
  if (!v) {
    return "--"
  }
  return moment.unix(v).format("MMM DD, YYYY")
})

Vue.filter("formatDate", function (value) {
  if (!value) {
    return "--"
  }
  const date = new Date(value)
  return moment.utc(date).format("MMM DD, YYYY")
})
Vue.filter("formatDateNoYear", function (value) {
  if (!value) {
    return "--"
  }
  const date = new Date(value)
  return moment.utc(date).format("MMM DD")
})

Vue.filter("formatDateTime", function (value) {
  if (!value) {
    return "--"
  }
  const date = new Date(value)
  return moment.utc(date).format("MMM DD, YYYY hh:mm A")
})

Vue.filter("formatTime", function (value) {
  return value
})

Vue.filter("formatHours", function (value) {
  return value.toFixed(2)
})

Vue.filter("formatToFixed", function (value) {
  return Number(value)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
})

Vue.filter("formatLocalDate", function (value) {
  if (!value) {
    return "--"
  }
  const date = new Date(value)
  return moment.utc(date).local().format("MMM DD, YYYY")
})
Vue.filter("formatLocalDateTime", function (value) {
  if (!value) {
    return "--"
  }
  const date = new Date(value)
  return moment.utc(date).local().format("MMM DD, YYYY hh:mm A")
})
Vue.filter("formatMoney", function (value) {
  if (!value) {
    return "--"
  } else {
    return (
      "$" + (value / 100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    )
  }
})
Vue.filter("pluralize", function (n, w) {
  if (n === 1) {
    return w
  } else {
    return w + "s"
  }
})

Vue.filter("formatTime", function (value) {
  // return moment(value).format("h:mm A");
  return moment(value, ["h:mm A"]).format("h:mm A")
})

Vue.filter("formatNotification", function (value) {
  return value
})
Vue.filter("titleCase", function (value) {
  let str = value.toLowerCase()
  str = str.split(" ")

  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
  }
  return str.join(" ")
})

Vue.filter("timeAgo", function (value) {
  function pluralize (n, w) {
    if (n === 1) {
      return n + " " + w
    } else {
      return n + " " + w + "s"
    }
  }
  const timeDiff = new Date() - new Date(value)
  const between = timeDiff / 1000
  if (between < 3600) {
    return pluralize(~~(between / 60), " minute")
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), " hour")
  } else {
    return pluralize(~~(between / 86400), " day")
  }
})
