import globalActions from "../api/GlobalActions.js"
import globalMutations from "../api/GlobalMutations.js"
import globalGetters from "../api/GlobalGetters.js"

export const state = () => ({
  isStale: true,
  latest_fetch: "",
  type: "omsettings",
  pageLayout: "StructureTable",
  canEdit: false,
  canView: false,
  effectedByYear: false,
  yearFields: [],
  aliasField: "year",
  filterOverrides: ["audits"],
  relations: [
    {
      name: "audits",
      translate: "audits",
      label: "Audits",
      value: "audits",
      storename: "audits",
      yearFields: [],
      export: false
    }
  ],
  all: {},
  reference: {},
  active: false,
  omOfYear: false,
  page: 1,
  modelLabels: {
    single: "OM Setting",
    plural: "OM Settings"
  },

  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: "year",
    page: 1,
    sortDesc: []
  },
  print_path: "",
  search: null,
  searchFields: ["year"],
  total: 0,
  print: false,
  export: false,
  bound: {
    // year: "",
    // construction: "",
    // basic_allotment: "",
    // o_m: "",
    // excess: "",
    // current_use_constant: "",
    // discount_start: "",
    // discount_end: "",
    // discount: "",
  },
  filter: {
    // year: "",
  },
  quickFilters: [],
  include: "audits",
  headers: {},
  stub: {
    audits: []
  },
  notifications: {}
})

// Mutations ==================

export const mutations = {
  ...globalMutations,
  SET_OM_OF_YEAR (state, om) {
    state.omOfYear = om
  }
}

// Actions ====================

export const actions = {
  ...globalActions,
  async set_om_of_year ({ commit, state, getters, dispatch, rootState }, om) {
    await commit("SET_OM_OF_YEAR", om)
  }
}

// Getters ====================

export const getters = {
  ...globalGetters
}
