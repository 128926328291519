import Vue from "vue"
import _ from "lodash"
import VeeValidate from "vee-validate"
// import bugsnag from "@bugsnag/js"
// import bugsnagVue from "@bugsnag/plugin-vue"

const ComponentContext = require.context(
  "../components/common",
  true,
  /\.vue$/i,
  "lazy"
)

ComponentContext.keys().forEach((componentFilePath) => {
  const componentName = componentFilePath.split("/").pop().split(".")[0]
  Vue.component(componentName, () => ComponentContext(componentFilePath))
})

if (!Vue.__my_mixin__) {
  Vue.__my__mixin__ = true
  Vue.mixin({
    methods: {
      showErrorSnackInfinitely (messageObject) {
        /*
     * messageObject must be an object with content and color properties.
     * content is the actual message to display,
     * color is the color of the message (usually "error" or "success")
     */
        if (typeof messageObject !== "object" || !messageObject.content || !messageObject.color) {
          throw new Error("messageObject must be an object with content and color properties")
        }
        this.$store.commit("snackbar/showMessage", messageObject)
      },
      showErrorSnack (messageObject) {
        /*
     * messageObject must be an object with content and color properties.
     * content is the actual message to display,
     * color is the color of the message (usually "error" or "success")
     */
        if (typeof messageObject !== "object" || !messageObject.content || !messageObject.color) {
          throw new Error("messageObject must be an object with content and color properties")
        }
        this.$store.commit("snackbar/showMessage", messageObject)
      },
      showSuccessSnack (message) {
        this.$store.dispatch("snackbar/showMessage", {
          content: {
            message,
            status: 201
          },
          color: "success"
        })
      },
      isEmptyObject (obj) {
        return JSON.stringify(obj) === "{}"
      },
      cloneObj (obj) {
        return JSON.parse(JSON.stringify(obj))
      },
      objHas (obj, field) {
        if (typeof obj === "object") {
          return Object.prototype.hasOwnProperty.call(obj, field)
        }
        return false
      },
      objToArray (obj) {
        return Object.values(this.cloneObj(obj))
      },
      objHasAndNotEmpty (obj, field) {
        if (this.objHas(obj, field)) {
          if (obj[field]) {
            return true
          }
        }
        return false
      },
      fullTextSearch (array, value) {
        array.filter(data => JSON.stringify(data).replace(/("\w+":)/g, "").toLowerCase().includes(value.toLowerCase()))
      },

      // region filters

      // !!! don't forget to add any new filter function to the dictionaries in the HorizontalSearchBar.vue !!!
      like (array, key, value) {
        return array.filter(data => JSON.stringify(data[key]).replace(/("\w+":)/g, "").toLowerCase().includes(value.toLowerCase()))
      },
      truthy (array, key, value) {
        if (value) {
          return array.filter(data => Boolean(data[key]))
        }
        return array.filter(data => !data[key])
      },
      eq (array, key, value) {
        return array.filter(data => data[key] === value)
      },
      ltFloat (array, key, value) {
        const results = array.filter(data => parseFloat(data[key]) < parseFloat(value))
        return results
      },
      lt (array, key, value) {
        console.log({ array, key, value })
        const results = array.filter(data => data[key] < value)
        return results
      },
      lt_eq (array, key, value) {
        const results = array.filter((data) => {
          return data[key] && data[key] <= value
        })
        return results
      },
      gt (array, key, value) {
        const results = array.filter(data => data[key] > value)

        return results
      },
      gt_eq (array, key, value) {
        const results = array.filter(data => data[key] >= value)

        return results
      },
      gtFloat (array, key, value) {
        const results = array.filter(data => data[key] > parseFloat(value))

        return results
      },
      arrHas (array, key, value) {
        if (value === "none") {
          return array.filter(data => !data[key].length)
        }
        return array.filter(data => data[key].length >= value)
      },
      any (array, key, value) {
        if (value === "true") {
          const results = array.filter(data => data[key] !== null)

          return results
        }
        const results = array.filter(data => data[key] === null)

        return results
      },
      gt_eq_any (array, key, value) {
        if (value === "true") {
          return array.filter(data => data[key] !== null)
        }

        return array.filter((data) => {
          return data[key] === null || data[key] >= value
        })
      },
      isIn (array, key, values) {
        if (typeof values === "string") {
          values = values.split(",").map(v => v.toString().trim())
        }

        return array.filter(data => values.indexOf(data[key]) > 0)
      },
      in (array, key, values) {
        if (typeof values === "string") {
          values = values.split(",").map(v => v.toString().trim())
        }

        return array.filter(data => values.includes(data[key]))
      },
      notIn (array, key, values) {
        if (typeof values === "string") {
          values = values.split(",").map(v => v.toString().trim())
        }

        return array.filter(data => !values.includes(data[key]))
      },
      isEmpty (array, key) {
        return array.filter(data => data[key] === null || data[key] === "")
      },
      isNotEmpty (array, key) {
        return array.filter(data => data[key] !== null && data[key] !== "")
      },
      find (array, key, value) {
        const results = array.filter((item) => {
          if (!item[key]) {
            return false
          }
          const relationsToFind = value.split(",")

          if (Array.isArray(item[key])) {
            const ids = item[key]
            return ids.some(r => relationsToFind.includes(r.toString()))
          }

          return relationsToFind.includes(item[key].toString())
        })
        return results
      },
      getOrdersInOmYear (orders, om) {
        return orders.filter((order) => {
          const orderOn = this.$moment(order.on_date + " " + (order.on_time !== null ? order.on_time : "00:00"), "YYYY-MM-DD HH:mm")
          const orderStartsInYear = orderOn >= this.$moment(om.water_year_start + " 00:00:00", "YYYY-MM-DD")

          let orderEndsInYear = true
          if (order.off_date) {
            const orderOff = this.$moment(order.off_date + " " + (order.off_time !== null ? order.off_time : "00:00"), "YYYY-MM-DD HH:mm")
            orderEndsInYear = orderOff <= this.$moment(om.water_year_end + " 23:59:59", "YYYY-MM-DD")
          }

          return orderStartsInYear && orderEndsInYear
        })
      },
      filterRunningOrders (orders) {
        return orders.filter((order) => {
          const orderOn = this.$moment(order.on_date + " " + (order.on_time !== null ? order.on_time : "00:00"), "YYYY-MM-DD HH:mm")
          const onBeforeNow = orderOn <= this.$moment()

          let offAfterNow = true
          if (order.off_date) {
            const orderOff = this.$moment(order.off_date + " " + (order.off_time !== null ? order.off_time : "00:00"), "YYYY-MM-DD HH:mm")
            offAfterNow = orderOff >= this.$moment()
          }

          return onBeforeNow && offAfterNow
        })
      },
      getTotalPartialBilling (orders, om) {
        return orders.reduce((accm, order) => accm + Number(this.generatePartialBilling(order, om)), 0.00)
      },
      generateOrderHours (order) {
        if (order.on_date && (!order.revoked_at || (order.revoked_at && order.reinstated_at))) {
          const start = this.$moment(order.on_date + " " + (order.on_time ? order.on_time : "00:00"), "YYYY-MM-DD HH:mm")
          if (start > this.$moment() && !order.off_date) {
            return 0
          }

          let endDate = this.$moment()

          if (order.off_date) {
            endDate = this.$moment(order.off_date + " " + (order.off_time !== null ? order.off_time : "00:00"), "YYYY-MM-DD HH:mm")
          }

          const duration = this.$moment.duration(endDate.diff(start))
          return Number(duration.asHours())
        }
        return 0
      },
      generatePartialBilling (order, om) {
        const discountStart = this.$moment(om.discount_start)
        const discountEnd = this.$moment(om.discount_end)
        const discount = Number(om.discount)
        const hourlyAF = Number(om.current_use_constant) / 24
        const flow = Number(order.flow)
        const orderOn = this.$moment(order.on_date + " " + (order.on_time !== null ? order.on_time : "00:00"), "YYYY-MM-DD HH:mm")
        const orderOff = this.$moment()
        let hours = 0
        let af = 0
        let discountAF = 0
        // const discountHours = 0
        if (!order.on_date || order.revoked_at || orderOn >= this.$moment() || order.off_date) {
          return 0.00
        }

        if (orderOn >= discountStart && orderOff <= discountEnd) {
          // Entire order is in discount

          const duration = this.$moment.duration(orderOff.diff(orderOn))
          hours = Number(duration.asHours())

          af = hourlyAF * hours * flow
          af = Number(af) * discount
        } else if (orderOn >= discountEnd || orderOff <= discountStart) {
          // entire order is out of discount
          const duration = this.$moment.duration(orderOff.diff(orderOn))
          hours = Number(duration.asHours())
          af = hourlyAF * hours * flow
        } else if (orderOn >= discountStart && orderOff >= discountEnd) {
          // starts in discount, ends out of discount
          const discountHours = Number(this.$moment.duration(discountEnd.diff(orderOn)).asHours())
          hours = Number(this.$moment.duration(orderOff.diff(discountEnd)).asHours())

          discountAF = discountHours * hourlyAF * flow
          discountAF = Number(discountAF) * discount
          af = hourlyAF * hours * flow
          af = Number(af) + Number(discountAF)
        } else if (orderOn <= discountStart && orderOff <= discountEnd) {
          // starts out of discount, ends in discount
          hours = this.$moment.duration(discountStart.diff(orderOn)).asHours()
          const discountHours = Number(this.$moment.duration(orderOff.diff(discountStart)).asHours())

          discountAF = discountHours * hourlyAF * flow
          discountAF = Number(discountAF) * discount
          af = hourlyAF * hours * flow
          af = Number(af) + Number(discountAF)
        } else if (orderOn <= discountStart && orderOff >= discountEnd) {
          // discounted dates are contained within order on-off date range
          hours = Number(this.$moment.duration(discountStart.diff(orderOn)).asHours())
          const discountHours = Number(this.$moment.duration(discountEnd.diff(discountStart)).asHours())
          hours = hours + Number(this.$moment.duration(orderOff.diff(discountEnd)).asHours())

          discountAF = (discountHours * hourlyAF * flow) * discount
          af = hourlyAF * hours * flow
          af = Number(af) + Number(discountAF)
        } else {
          // catch all - no discount
          // entire order is out of discount
          const duration = this.$moment.duration(orderOff.diff(orderOn))
          hours = Number(duration.asHours())

          af = hourlyAF * hours * flow
        }
        return af
      },
      getFullyBilled (orders) {
        return orders.filter(o => o.off_date && o.off_time)
          .reduce((acc, order) => acc + Number(order.acre_ft), 0.00)
      },
      getTotalAcresProduced (producer) {
        return producer.producesFu.reduce((acc, fu) => acc + Number(fu.irrigated_acres), 0.00)
      },
      getTotalAcresOwned (owner) {
        return owner.ownsFu.reduce((acc, fu) => acc + Number(fu.irrigated_acres), 0.00)
      }
      // !!! don't forget to add any new filter function to the dictionaries in the HorizontalSearchBar.vue !!!
      // endregion
    }
  })
}

// if (navigator.onLine) {
//   const bugsnagClient = bugsnag("ced09328ed4a0b3096530f210165f763'")
//   bugsnagClient.use(bugsnagVue, Vue)
// }

Vue.use(_)
Vue.use(VeeValidate)
