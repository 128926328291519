import moment from "moment"
export default {
  // apps quickmenu

  events: [
    {
      title: "Date",
      items: [
        {
          title: "Upcoming Events (90 days)",
          value: "attr_within_days_next",
          schema: {
            attr_within_days_next: {
              start_date: {
                end: 90
              }
            }
          }
        },
        {
          title: "Recent Events (Last 90 days)",
          value: "attr_within_days_past",
          schema: {
            attr_within_days_past: {
              start_date: {
                end: 90
              }
            }
          }
        }
      ]
    },
    {
      title: "Status",
      items: [
        {
          title: "Archived",
          value: "exact",
          schema: {
            exact: {
              is_archived: true
            }
          }
        },
        {
          title: "Draft",
          value: "exact",
          schema: {
            exact: {
              is_live: false
            }
          }
        },
        {
          title: "Published",
          value: "exact",
          schema: {
            exact: {
              is_live: true
            }
          }
        }
      ]
    }
  ],
  // user dropdown menu
  members: [
    {
      title: "Status",
      items: [
        {
          title: "Active",
          value: "exact",
          schema: {
            exact: {
              status: ["Active"]
            }
          }
        },
        {
          title: "Inactive",
          value: "exact",
          schema: {
            exact: {
              status: ["Inactive"]
            }
          }
        },
        {
          title: "Honorary",
          value: "exact",
          schema: {
            exact: {
              status: ["Honorary"]
            }
          }
        }
      ]
    },
    {
      title: "Membership Type",
      active: true,
      items: [
        {
          title: "Builder",
          value: "exact",
          schema: {
            exact: {
              member_type: ["Builder"],
              status: ["Active"]
            }
          }
        },
        {
          title: "Associate",
          value: "exact",
          schema: {
            exact: {
              member_type: ["Associate"],
              status: ["Active"]
            }
          }
        },
        {
          title: "Affiliate",
          value: "exact",
          schema: {
            exact: {
              member_type: ["Affiliate"],
              status: ["Active"]
            }
          }
        }
      ]
    },
    {
      title: "Anniversary",
      items: [
        {
          title: "Past Due",
          value: "attr_before_date",
          schema: {
            attr_before_date: {
              anniversary: {
                start: moment()
                  .format("YYYY-MM-DD")
                  .toString()
              }
            },
            exact: {
              status: ["Active"]
            }
          }
        },
        {
          title: "This month",
          value: "attr_this_month",
          schema: {
            attr_this_month: {
              anniversary: {}
            },
            exact: {
              status: ["Active"]
            }
          }
        },
        {
          title: "Next 90 days",
          value: "attr_within_days_next",
          schema: {
            attr_within_days_next: {
              anniversary: {
                end: 90
              }
            },
            exact: {
              status: ["Active"]
            }
          }
        }
      ]
    }
  ],
  pages: [
    {
      title: "Status",
      items: [
        {
          title: "Draft",
          value: "exact",
          schema: {
            exact: {
              is_live: false
            }
          }
        },
        {
          title: "Published",
          value: "exact",
          schema: {
            exact: {
              is_live: true
            }
          }
        }
      ]
    }
  ],
  payments: [
    {
      title: "Date",
      items: [
        {
          title: "Recent Payments (Last 90 days)",
          value: "attr_within_days_past",
          schema: {
            attr_within_days_past: {
              payment_date: {
                end: 90
              }
            }
          }
        }
      ]
    },
    {
      title: "Status",
      items: [
        {
          title: "Archived",
          value: "exact",
          schema: {
            exact: {
              is_archived: true
            }
          }
        },
        {
          title: "Draft",
          value: "exact",
          schema: {
            exact: {
              is_live: false
            }
          }
        },
        {
          title: "Published",
          value: "exact",
          schema: {
            exact: {
              is_live: true
            }
          }
        }
      ]
    },
    {
      title: "Payment Method",
      items: [
        {
          title: "Credit Card",
          value: "exact",
          schema: {
            exact: {
              payment_method: "cc"
            }
          }
        },
        {
          title: "Check",
          value: "exact",
          schema: {
            exact: {
              payment_method: "check"
            }
          }
        }
      ]
    },
    {
      title: "Payment Type",
      items: [
        {
          title: "Event",
          value: "exact",
          schema: {
            exact: {
              payment_method: "Event"
            }
          }
        },
        {
          title: "Gift",
          value: "exact",
          schema: {
            exact: {
              payment_method: "Gift"
            }
          }
        },
        {
          title: "Membership",
          value: "exact",
          schema: {
            exact: {
              payment_type: "membership"
            }
          }
        },
        {
          title: "Sponsorship",
          value: "exact",
          schema: {
            exact: {
              payment_method: "Sponsorship"
            }
          }
        }
      ]
    }
  ],
  tasks: [
    {
      title: "My Tasks",
      items: [
        {
          title: "Past Due",
          value: "attr_before_date",
          schema: {
            attr_before_date: {
              due_on: {
                start: moment()
                  .format("YYYY-MM-DD")
                  .toString()
              }
            },
            exact: {
              is_complete: false
            }
          }
        },
        {
          title: "Due soon (10 days)",
          value: "attr_within_days_next",
          schema: {
            attr_within_days_next: {
              due_on: {
                end: 10
              }
            },
            exact: {
              is_complete: false
            },
            relations: {
              author: {
                with_rel: {
                  id: 6
                }
              }
            }
          }
        }
      ]
    }
  ]
}
