import globalActions from "../api/GlobalActions.js"
import globalMutations from "../api/GlobalMutations.js"
import globalGetters from "../api/GlobalGetters.js"

export const state = () => ({
  isStale: true,
  latest_fetch: "",
  type: "diversionPoints",
  effectedByYear: false,
  yearFields: [],
  aliasField: "uniqueName",
  modelLabels: {
    single: "Turnout",
    plural: "Turnouts"
  },
  filterOverrides: ["audits"],
  relations: [
    {
      name: "audits",
      translate: "audits",
      label: "Audits",
      value: "audits",
      storename: "audits",
      yearFields: [],
      export: false
    },
    {
      name: "flags",
      translate: "flags",
      label: "Flag",
      value: "flags",
      storename: "flags",
      yearFields: []
    },
    {
      name: "producer",
      translate: "producer",
      label: "Producer",
      value: "contacts",
      storename: "contacts",
      yearFields: []
    },
    {
      name: "farmunit",
      translate: "farmunit",
      label: "Farm Unit",
      value: "farmunits",
      storename: "farmunits",
      yearFields: []
    },
    {
      name: "irrigationType",
      translate: "irrigationType",
      label: "Irrigation",
      value: "irrigationTypes",
      storename: "irrigationTypes",
      yearFields: [],
      export: true
    },
    {
      name: "canal",
      translate: "canals",
      label: "Canal",
      value: "canals",
      storename: "canals",
      yearFields: []
    },
    {
      name: "district",
      translate: "districts",
      label: "District",
      value: "districts",
      storename: "districts",
      yearFields: []
    },
    {
      name: "orders",
      translate: "orders",
      label: "Orders",
      value: "orders",
      storename: "orders",
      yearFields: []
    },
    // {
    //   name: "usages",
    //   translate: "usages",
    //   value: "usages",
    //   storename: "usages",
    //   yearFields: ["off_contacted_date", "off_requested_date", "on_date", "off_date"]
    // },
    {
      name: "revokedOrders",
      translate: "orders",
      label: "Revoked Orders",
      value: "orders",
      storename: "orders",
      yearFields: []
    }
  ],
  all: {},
  reference: {},
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: null,
    sort: true,
    sortBy: "farmunit.producer.full_name",
    page: 1,
    sortDesc: []
  },
  print_path: "",
  search: null,
  searchFields: ["name", "canalName", "irrigationTypeName"],
  total: 0,
  print: false,
  export: false,
  bound: { is_active: 1 },
  filter: {},
  quickFilters: [],
  stub: {
    audits: [],
    canal: {},
    district: {},
    farmunit: {},
    farmunits: [],
    orders: [],
    usages: [],
    revokedOrders: [],
    producer: {},
    flags: []
  },
  // members are called contractors on the server
  include: "farmunit,orders,revokedOrders,audits",
  headers: {},
  notifications: {}
})

// Mutations ==================

export const mutations = {
  ...globalMutations
}

// Actions ====================

export const actions = {
  ...globalActions,
  async set_orders_card_active (
    { commit, state, getters, dispatch, rootState },
    id
  ) {
    await commit("active", id)
    return this.$router.push(`/diversionPoints/${id}/orders`)
  }
}

// Getters ====================

export const getters = {
  ...globalGetters
}
