import { schema } from "normalizr"

export default (ctx, inject) => {
  const relationships = {
    audits: new schema.Entity("audits"),
    announcements: new schema.Entity("announcements"),
    addressHistory: new schema.Entity("addressHistory"),
    canals: new schema.Entity("canals"),
    dailycfs: new schema.Entity("dailycfs"),
    dailycfsFilters: new schema.Entity("dailycfsFilters"),
    contacts: new schema.Entity("contacts"),
    cropTypes: new schema.Entity("cropTypes"),
    districts: new schema.Entity("districts"),
    diversionPoints: new schema.Entity("diversionPoints"),
    farmunits: new schema.Entity("farmunits"),
    filters: new schema.Entity("filters"),
    flags: new schema.Entity("flags"),
    files: new schema.Entity("files"),
    irrigationTypes: new schema.Entity("irrigationTypes"),
    maintenanceRequests: new schema.Entity("maintenanceRequests"),
    memberHeaders: new schema.Entity("memberHeaders"),
    notes: new schema.Entity("notes"),
    offlineRequests: new schema.Entity("offlineRequests"),
    observations: new schema.Entity("observations"),
    omsettings: new schema.Entity("omsettings"),
    orders: new schema.Entity("orders"),
    ownershipHistory: new schema.Entity("ownershipHistory"),
    producerHistory: new schema.Entity("producerHistory"),
    syncStats: new schema.Entity("syncStats"),
    syncHistory: new schema.Entity("syncHistory"),
    usages: new schema.Entity("usages"),
    users: new schema.Entity("users"),
    appInfo: new schema.Entity("appInfo")
  }

  relationships.announcements.define({})
  relationships.appInfo.define({})

  relationships.canals.define({
    orders: [relationships.orders]
  })

  relationships.contacts.define({
    addressHistory: [relationships.addressHistory],
    notes: [relationships.notes]
  })

  relationships.dailycfs.define({
    canal: [relationships.canals]
  })
  relationships.dailycfsFilters.define({})

  relationships.diversionPoints.define({
  })

  relationships.districts.define({
  })

  relationships.cropTypes.define({
  })

  relationships.farmunits.define({
  })

  relationships.filters.define({})

  relationships.files.define({})

  relationships.flags.define({
  })

  relationships.irrigationTypes.define({
  })

  relationships.maintenanceRequests.define({
    files: [relationships.files]
  })

  relationships.memberHeaders.define({})

  relationships.notes.define({
  })

  relationships.observations.define({
    user: relationships.contacts,
    updatedBy: relationships.contacts
  })

  relationships.omsettings.define({
  })

  relationships.orders.define({
  })

  relationships.ownershipHistory.define({
    owner: relationships.contacts
  })

  relationships.producerHistory.define({
    producer: relationships.contacts
  })

  relationships.addressHistory.define({
    contact: relationships.contacts
  })

  relationships.syncStats.define({})

  relationships.usages.define({
    audits: [relationships.audits]
  })

  relationships.users.define({
    notes: [relationships.notes]
  })

  inject("relationships", relationships)
}
