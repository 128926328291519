import { normalize } from "normalizr"
import globalActions from "../api/GlobalActions.js"
import globalMutations from "../api/GlobalMutations.js"
import globalGetters from "../api/GlobalGetters.js"

export const state = () => ({
  type: "appInfo",
  pageLayout: "StructureTable",
  canEdit: false,
  canView: false,
  effectedByYear: true,
  yearFields: [],
  aliasField: "api_version",
  relations: [],
  all: {},
  reference: {
    /* for showing paginated resources ... needs a bit more work. */
  },
  modelLabels: {
    single: "App Info",
    plural: "App Info"
  },
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: "run_date",
    page: 1,
    sortDesc: []
  },
  total: 0,
  print: false,
  export: false,
  print_path: "",
  stub: {},

  bound: {},
  filter: {},
  include: "",
  headers: {},
  notifications: {}
})

// Mutations ==================

export const mutations = {
  ...globalMutations
}

// Actions ====================

export const actions = {
  ...globalActions,
  async getAppInfo ({ commit, state, getters, dispatch, rootState }) {
    if (!navigator.onLine) {
      return
    }
    const params = getters.params
    if (params.hasOwnProperty("print")) {
      delete params.print
    }
    if (params.hasOwnProperty("export")) {
      delete params.export
    }
    // if params has print/export, remove it

    const response = await this.$repositories[state.type].index(params)
    if (response.hasOwnProperty("error")) {
      return
    }

    const data = response.data

    const { entities } = normalize(
      [data],
      [this.$relationships[state.type]]
    )
    commit("REPLACE_ALL", entities[state.type])

    commit("SET_FETCH_DATE")
  }
  /* async list ({ commit, state, getters, dispatch, rootState }) {
    if (!navigator.onLine) {
      return
    }
    const params = getters.params
    if (params.hasOwnProperty("print")) {
      delete params.print
    }
    if (params.hasOwnProperty("export")) {
      delete params.export
    }
    // if params has print/export, remove it

    const response = await this.$repositories[state.type].index(params)
    dispatch("set_snack", response)
    if (response.hasOwnProperty("error")) {
      return
    }

    const data = response.data
    console.log("APP INFO", data, response)

    const { entities } = normalize(
      [data],
      [this.$relationships[state.type]]
    )

    console.log()

    commit("REPLACE_ALL", entities[state.type])

    commit("SET_FETCH_DATE")
  } */
}

// Getters ====================

export const getters = {
  ...globalGetters,
  lastUpdated (state, getters) {
    const all = Object.values(state.all ?? [])

    if (all.length) {
      return all[0].last_updated_at
    }
    return ""
  },
  version (state, getters) {
    let all = Object.values(state.all)
    if (!all.length) {
      all = {
        0: {
          nuxt_client_version: "0.0.0"
        }
      }
    }

    return all[0].nuxt_client_version
  }
}
