export default function ({ $auth, next, store }) {
  const user = $auth.$state.user
  console.log(user.data.roles)
  if (!user) {
    next("/login")
  }
  if (user.data.roles.includes("ditchrider")) {
    next("/diversionPoints")
  }
}
