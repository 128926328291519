import globalActions from "../api/GlobalActions.js"
import globalMutations from "../api/GlobalMutations.js"
import globalGetters from "../api/GlobalGetters.js"

export const state = () => ({
  isStale: true,
  type: "usages",
  canEdit: false,
  canView: false,
  effectedByYear: true,
  yearFields: ["on_date", "off_date"],
  aliasField: "order_no",
  relations: [
    {
      name: "farmunit",
      translate: "farmunit",
      value: "farmunits",
      storename: "farmunits",
      yearFields: []
    },
    {
      name: "user",
      translate: "user",
      value: "users",
      storename: "users",
      yearFields: []
    },
    {
      name: "order",
      translate: "orders",
      value: "orders",
      storename: "orders",
      yearFields: []
    },
    {
      name: "canal",
      translate: "canal",
      value: "canals",
      storename: "canals",
      yearFields: []
    },
    {
      name: "diversionPoint",
      translate: "diversionPoint",
      value: "diversionPoints",
      storename: "diversionPoints",
      yearFields: []
    },
    {
      name: "irrigationType",
      translate: "irrigationTypes",
      value: "irrigationTypes",
      storename: "irrigationTypes",
      yearFields: []
    },
    {
      name: "cropType",
      translate: "cropTypes",
      value: "cropTypes",
      storename: "cropTypes",
      yearFields: []
    }
  ],
  all: {},
  reference: {
    /* for showing paginated resources ... needs a bit more work. */
  },
  active: false,
  startDate: null,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: "on_date",
    page: 1,
    sortDesc: []
  },
  total: 0,
  print: false,
  export: false,
  print_path: "",
  bound: {},
  filter: {
    attr_between_date: {
      on_date: {
        start: new Date().getFullYear() + "-01-01",
        end: new Date().getFullYear() + "-12-31"
      },
      off_date: {
        start: new Date().getFullYear() + "-01-01",
        end: new Date().getFullYear() + "-12-31"
      }
    }
  },
  stub: {
    audits: [],
    canal: {},
    order: {},
    diversionPoint: {},
    user: {},
    producer: {},
    farmunit: {}
  },
  notifications: {},
  include: "farmunit,canal,user,diversionPoint",
  headers: {}
})

// Mutations ==================

export const mutations = {
  ...globalMutations,
  setStartDate: (state, date) => {
    state.startDate = date
  }
}

// Actions ====================

export const actions = {
  ...globalActions
}

// Getters ====================

export const getters = {
  ...globalGetters
}
