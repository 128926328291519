import localForage from "localforage"
import moment from "moment"
import globalActions from "../api/GlobalActions.js"
import globalMutations from "../api/GlobalMutations.js"
import globalGetters from "../api/GlobalGetters.js"

export const state = () => ({
  isStale: true,
  latest_fetch: "",
  type: "orders",
  pageLayout: "StructureTable",
  inlineEdit: true,
  canEdit: false,
  canView: false,
  effectedByYear: true,
  yearFields: [],
  shortTable: ["producer", "flow", "diversionPoint"],
  filterHideOverrides: [
    "audits",
    "on_time",
    "off_time",
    "on_contacted_by",
    "on_contacted_date",
    "on_requested_time",
    "on_contacted_time",
    "off_contacted_by",
    "off_contacted_time",
    "off_requested_time",
    "off_contacted_date"
  ],
  aliasField: "order_no",
  modelLabels: {
    single: "Order",
    plural: "Orders"
  },
  relations: [
    {
      name: "audits",
      translate: "audits",
      label: "Audits",
      value: "audits",
      storename: "audits",
      yearFields: [],
      export: false
    },
    {
      name: "flags",
      translate: "flags",
      label: "Flag",
      value: "flags",
      storename: "flags",
      yearFields: []
    },
    {
      name: "farmunit",
      translate: "farmunits",
      label: "Farm Unit",
      value: "farmunits",
      storename: "farmunits",
      yearFields: []
    },
    {
      name: "canal",
      translate: "canal",
      label: "Canal",
      value: "canals",
      storename: "canals",
      yearFields: [],
      export: true
    },
    {
      name: "diversionPoint",
      translate: "diversionPoint",
      label: "Turnouts",
      value: "diversionPoints",
      storename: "diversionPoints",
      yearFields: [],
      export: true
    },
    {
      name: "district",
      translate: "district",
      value: "districts",
      label: "District",
      storename: "districts",
      yearFields: [],
      export: true
    },
    {
      name: "irrigationType",
      translate: "irrigationTypes",
      label: "Irrigation Method",
      value: "irrigationTypes",
      storename: "irrigationTypes",
      yearFields: []
    },
    {
      name: "cropType",
      translate: "cropTypes",
      label: "Crop Type",
      value: "cropTypes",
      storename: "cropTypes",
      yearFields: []
    },
    {
      name: "producer",
      translate: "contacts",
      label: "Producer",
      value: "contacts",
      storename: "contacts",
      yearFields: []
    },
    {
      name: "user",
      translate: "users",
      label: "Created By User",
      value: "users",
      storename: "users",
      yearFields: []
    }
    // {
    //   name: "onContactor",
    //   translate: "contacts",
    //   value: "onContactor",
    //   storename: "contacts",
    //   yearFields: []
    // },
    // {
    //   name: "offContactor",
    //   translate: "contacts",
    //   value: "offContactor",
    //   storename: "contacts",
    //   yearFields: []
    // },
    // {
    //   name: "orderHistories",
    //   translate: "orderHistories",
    //   value: "orderHistories",
    //   storename: "orderHistories",
    //   yearFields: []
    // }
  ],
  all: {},
  reference: {
    /* for showing paginated resources ... needs a bit more work. */
  },
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: "order_no",
    page: 1,
    sortDesc: []
  },
  total: 0,
  print: false,
  export: false,
  search: null,
  searchFields: ["order_no", "record_no", "start_date", "flow"],
  print_path: "",
  stub: {
    audits: [],
    canal: {},
    diversionPoint: {},
    farmunit: {},
    user: {},
    producer: {},
    owners: [],
    orderHistories: [],
    flags: []
  },

  bound: {},
  filter: {},
  quickFilters: [
    {
      label: "Status",
      name: "status",
      order: 1,
      value: [
        {
          label: "Scheduled",
          desc: "Orders with a 'requested on' date in the future",
          order: 1,
          schema: { on_requested_date: `gt(${moment().format("YYYY-MM-DD")})` }
        },
        {
          label: "Running",
          desc: "A list of orders that are currently on",
          order: 2,
          schema: {
            on_date: `lt(${moment().format("YYYY-MM-DD")})`,
            off_date: "any(false)"
          }
        },
        {
          label: "Scheduled - off",
          desc: "A list of orders scheduled to be turned off",
          order: 3,
          schema: { off_requested_date: `gt(${moment().format("YYYY-MM-DD")})` }
        },
        {
          label: "Completed",
          desc: "A list of orders that have been turned off",
          order: 4,
          schema: { off_date: "any(true)" }
        },
        /* {
          label: "W/O Revoked",
          desc: "Ignore revoked orders",
          order: 5,
          schema: {
            revoked_at: "isEmpty()"
          }
        }, */
        {
          label: "Revoked",
          desc: "A list of orders that are revoked and not instated again",
          order: 5,
          schema: {
            revoked_at: "isNotEmpty()",
            reinstated_at: "isEmpty()"
          }
        },
        {
          label: "Reinstated",
          desc: "A list of orders that are once revoked and later instated",
          order: 6,
          schema: {
            revoked_at: "isNotEmpty()",
            reinstated_at: "isNotEmpty()"
          }
        }
      ]
    },
    {
      label: "District",
      component: "SearchProducersByDistrict",
      name: "districts", // do we need this?
      relationName: "district",
      field: "name",
      description: "Find orders by one or more districts",
      order: 2
    }
    // {
    //   label: "District",
    //   name: "district",
    //   order: 2,
    //   value: [
    //     {
    //       label: "Orders in District 1",
    //       desc: "A list of orders from District D1",
    //       order: 1,
    //       schema: { "district.name": "eq(1)" }
    //     },
    //     {
    //       label: "Orders in District 2",
    //       desc: "A list of orders from District D2",
    //       order: 2,
    //       schema: { "district.name": "in(2E, 2W)" }
    //     },
    //     {
    //       label: "Orders in District 2E",
    //       desc: "A list of orders from District D2E",
    //       order: 3,
    //       schema: { "district.name": "eq(2E)" }
    //     },
    //     {
    //       label: "Orders in District 2W",
    //       desc: "A list of orders from District D2W",
    //       order: 4,
    //       schema: { "district.name": "eq(2W)" }
    //     },
    //     {
    //       label: "Orders in District 3",
    //       desc: "A list of orders from District D3",
    //       order: 5,
    //       schema: { "district.name": "eq(3)" }
    //     },
    //     {
    //       label: "Orders in District 4",
    //       desc: "A list of orders from District 4",
    //       order: 5,
    //       schema: { "district.name": "in(4K, 4S)" }
    //     },
    //     {
    //       label: "Orders in District 4K",
    //       desc: "A list of orders from District 4K",
    //       order: 6,
    //       schema: { "district.name": "eq(4k)" }
    //     },
    //     {
    //       label: "Orders in District 4S",
    //       desc: "A list of orders from District 4S",
    //       order: 7,
    //       schema: { "district.name": "eq(4S)" }
    //     },
    //     {
    //       label: "Orders in District 5",
    //       desc: "A list of orders from District 5",
    //       order: 8,
    //       schema: { "district.name": "eq(5)" }
    //     }
    //   ]
    // }
  ],
  include: "farmunit,canal,user,diversionPoint,producer,audits",
  headers: {},
  notifications: {}
})

// Mutations ==================

export const mutations = {
  ...globalMutations
}

// Actions ====================

export const actions = {
  ...globalActions,
  async running ({ state, commit, dispatch }, customParams) {
    const filter = {
      ...state.filter,
      isOrder: true,
      isDraft: "",
      isRevoked: "",
      isClosed: ""
    }

    await commit("replaceFilter", filter)
    return dispatch("list", customParams)
  },
  async draft ({ state, commit, dispatch }, customParams) {
    const filter = {
      ...state.filter,
      isOrder: "",
      isDraft: true,
      isRevoked: "",
      isClosed: ""
    }

    await commit("replaceFilter", filter)
    return dispatch("list", customParams)
  },
  async revoked ({ state, commit, dispatch }, customParams) {
    const filter = {
      ...state.filter,
      isOrder: "",
      isDraft: "",
      isRevoked: true,
      isClosed: ""
    }

    await commit("replaceFilter", filter)
    return dispatch("list", customParams)
  },
  async closed ({ state, commit, dispatch }, customParams) {
    const filter = {
      ...state.filter,
      isOrder: "",
      isDraft: "",
      isRevoked: "",
      isClosed: true
    }

    await commit("replaceFilter", filter)
    return dispatch("list", customParams)
  },
  async getNewOrderNumber ({ state, commit }, district) {
    if (navigator.onLine) {
      return await this.$repositories.getNewOrderNumber.create({ district })
    } else {
      let orderNo = 1
      const allOrders = Object.values(state.all)

      const sortedDistrictOrders = allOrders
        .filter(o => o.district && o.district.id === parseInt(district))
        .sort((a, b) => b.order_no - a.order_no)

      if (sortedDistrictOrders.length) {
        orderNo = parseInt(sortedDistrictOrders[0].order_no) + 1
      }

      return { new_order_no: orderNo }
    }
  }
}

// Getters ====================

export const getters = {
  ...globalGetters
}
