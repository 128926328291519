<template>
  <v-app>
    <v-system-bar v-if="!$nuxt.isOnline" app color="orange">
      <v-spacer />
    </v-system-bar>

    <v-navigation-drawer v-model="drawer" app class="white card-shadow">
      <v-img class="my-2 mx-3" src="/logo.jpg" />
      <v-divider />

      <v-divider class="" />
      <v-list class="primary--text" nav>
        <template v-for="(item, index) in menuItems">
          <v-list-item-group
            v-if="item.children"
            :key="item.title"
            active-class="primary lighten-2"
            :value="item.model"
          >
            <template #activator>
              <v-list-item class="ma-0 pa-0">
                <v-list-item-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title" />
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              class="ml-6"
              dense
              nuxt
              :to="child.to"
              exact
            >
              <v-list-item-action>
                <v-avatar tile class="grey lighten-3 shadow" size="30">
                  <v-icon small color="primary">
                    {{ child.icon }}
                  </v-icon>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="primary--text" v-text="child.title" />
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>

          <v-list-item
            v-else
            :key="index"
            :to="item.to"
            nuxt
            active-class="primary lighten-2 white--text"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <template #append>
        <v-card tile dark class="d-flex justify-center primary darken-2">
          <span class="text-h4 text-center">{{ time }}</span>
        </v-card>

        <v-card>
          <v-date-picker
            width="250"
            no-title
            readonly
            locale="en-US"
            show-current
            :value="currentDate"
          />
        </v-card>
      </template>
    </v-navigation-drawer>
    <v-app-bar
      clipped-right
      app
      elevation="4"
      class="pr-2"
      height="72px"
      color="primary lighten-2"
    >
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn v-bind="attrs" icon v-on="on" @click.stop="drawer = !drawer">
            <v-icon color="#ffffff">
              mdi-menu
            </v-icon>
          </v-btn>
        </template>
        <span>Open/Close Menu</span>
      </v-tooltip>
      <water-control key="is-nav" is-nav />

      <component :is="searchValue" />
      <v-spacer />
      <v-menu offset-y transition="slide-y-transition">
        <template #activator="{ on }">
          <v-btn icon color="primary lighten-2" v-on="on">
            <v-icon color="grey lighten-1">
              mdi-notebook-edit
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                mdi-note-plus
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              ref="observations_add_btn"
              @click="addObservation"
            >
              Add Observation
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                mdi-note-multiple
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              ref="observations_show_btn"
              @click="showObservations"
            >
              List Observations
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu offset-y left transition="slide-y-transition">
        <template #activator="{ on }">
          <v-btn icon color="primary lighten-2" v-on="on">
            <v-icon color="grey lighten-1">
              mdi-view-grid-outline
            </v-icon>
          </v-btn>
        </template>

        <v-card
          v-if="is_admin"
          class="d-flex flex-row flex-wrap"
          color="white"
          tile
          style="max-width: 280px"
        >
          <v-card
            v-for="app in adminsettings"
            :key="app.link"
            class="app-tile pa-3 text-center pointer"
            style="flex: 0 50%; border: thin solid rgba(0, 150, 136, 0.125)"
            flat
            outlined
            :to="app.to"
          >
            <v-icon color="primary">
              {{ app.icon }}
            </v-icon>
            <div class="font-weight-bold mt-1">
              {{ app.title }}
            </div>
            <!--            <div class="caption">-->
            <!--              {{ app.subtitle }}-->
            <!--            </div>-->
          </v-card>
        </v-card>
        <v-card
          v-else
          class="d-flex flex-row flex-wrap"
          color="white"
          tile
          style="max-width: 280px"
        >
          <v-card
            v-for="app in settings"
            :key="app.link"
            class="app-tile pa-3 text-center pointer"
            style="flex: 0 50%; border: thin solid rgba(0, 150, 136, 0.125)"
            flat
            outlined
            :to="app.to"
          >
            <v-icon color="primary">
              {{ app.icon }}
            </v-icon>
            <div class="font-weight-bold mt-1">
              {{ app.title }}
            </div>
            <!--            <div class="caption">-->
            <!--              {{ app.subtitle }}-->
            <!--            </div>-->
          </v-card>
        </v-card>
      </v-menu>

      <!-- <v-menu offset-y left transition="slide-y-transition">
        <template #activator="{ on }">
          <v-btn icon color="primary lighten-2" v-on="on">
            <v-badge
              small
              :content="`${notifications.length}`"
              overlap
              :color="notifications.length > 0 ? 'white' : 'primary'"
            >
              <v-icon color="grey lighten-1">
                mdi-bell-outline
              </v-icon>
            </v-badge>
          </v-btn>
        </template>

        <v-card>
          <v-list three-line dense max-width="400">
            <v-subheader class="pa-2 font-weight-bold">
              Notifications
            </v-subheader>

          </v-list>

          <div class="text-center pa-2">
            <small>Notifications Are Currently Under Construction</small>

          </div>
        </v-card>
      </v-menu> -->

      <v-menu nudge-bottom offset-y>
        <template #activator="{ on }">
          <v-btn icon color="primary lighten-2" v-on="on">
            <v-icon color="grey lighten-1">
              mdi-account
            </v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-if="is_admin" to="/users">
            <v-list-item-title>User Management</v-list-item-title>
          </v-list-item>
          <v-list-item @click.prevent="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <v-system-bar
        v-if="waterYear != latestWaterYear"
        class="systembar"
        dark
        color="#ca0b00"
      >
        <strong class="systembar-text">
          You're looking at data from an earlier water year!
        </strong>
      </v-system-bar>
      <v-system-bar
        v-if="!afterPrecache"
        class="systembar"
        dark
        color="#ca0b00"
      >
        <strong class="systembar-text">
          App is currently downloading, do not go offline!
        </strong>
      </v-system-bar>
      <v-container fill-height fluid page-height>
        <v-container v-if="isClient" class="pa-0" fluid>
          <Nuxt />
        </v-container>
        <div v-else class="container d-flex align-center justify-center">
          <img class="logo" src="/logo.jpg">
        </div>
      </v-container>
      <create-card
        ref="create_observation_card"
        :open="addObservationDialog"
        button-text="Submit"
        title-text="New Observation"
        class="mr-1"
        api-path="observations"
        model="observations"
        returns="observations"
        :outlined="true"
        method="create"
        :dark="false"
        @close="closeCreateObservation"
      />
      <ObservationsCard ref="observations_card" :displays="observationsDialogDisplays" @closeSignal="observationsDialogDisplays='None'" />
    </v-main>
    <SnackBar />
  </v-app>
</template>

<script>
import ObservationsCard from "@/components/common/cards/ObservationsCard.vue"

const cachedData = null

export default {
  components: { ObservationsCard },
  middleware: "waterYears",
  data () {
    return {
      fab: false,
      addObservationDialog: false,
      observationsDialogDisplays: "None",
      afterPrecache: false,
      interval: null,
      time: null,
      dock_toggle: null,
      waterDialog: false,
      wcopen: false,
      clipped: true,
      drawer: true,
      right: false,
      fixed: true,
      searchValue: "Search",
      isClient: false,
      waitStores: [],
      noWaitStores: [],
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      items: [
        {
          icon: "mdi-sync-circle",
          key: "dock",
          title: "Dock",
          to: "/dock"
        },
        {
          icon: "mdi-home",
          key: "dashboard",
          title: "Dashboard",
          to: "/"
        },
        {
          icon: "mdi-water",
          key: "orders",
          title: "Orders",
          model: 1,
          to: "/orders"
        },
        {
          icon: "mdi-waves",
          key: "canalSchedule",
          title: "Canal Scheduling",
          to: "/canalSchedule"
        },
        {
          icon: "mdi-account-box",
          key: "contacts",
          title: "Contacts",
          to: "/contacts"
        },

        {
          icon: "mdi-wrench",
          key: "maintenanceRequests",
          title: "Maintenance",
          to: "/maintenanceRequests"
        },
        {
          icon: "mdi-water-pump",
          key: "dailycfs",
          title: "Daily Canal Flows",
          to: "/dailycfs"
        }
      ],
      settings: [
        {
          icon: "home_work",
          title: "Farm Units",
          to: "/farmunits",
          subtitle: "List, add, edit or remove farm units."
        },
        {
          icon: "mdi-wifi-off",
          title: "Offline Requests",
          to: "/settings/offlineRequests"
        }
      ],
      adminsettings: [
        {
          icon: "home_work",
          title: "Farm Units",
          to: "/farmunits",
          subtitle: "List, add, edit or remove farm units."
        },
        {
          icon: "mdi-waves",
          title: "Canals",
          to: "/canals",
          subtitle: "List, add, edit or remove canals."
        },
        {
          icon: "mdi-map",
          title: "Districts",
          to: "/districts",
          subtitle: "List, add, edit or remove districts."
        },
        {
          icon: "mdi-flag",
          title: "Admin Flags",
          to: "/flags",
          subtitle: "List, add, edit or remove flags."
        },
        {
          icon: "settings",
          title: "O and M Settings",
          to: "/omsettings",
          subtitle: "List, add, edit O&M Settings."
        },
        {
          icon: "mdi-water-pump",
          title: "Irrigation Methods",
          to: "/irrigationTypes",
          subtitle: "List, add, edit or remove irrigation methods."
        },
        {
          icon: "mdi-spa-outline",
          title: "Crop Types",
          to: "/cropTypes",
          subtitle: "List, add, edit or remove crop types."
        },
        {
          icon: "mdi-wifi-off",
          title: "Offline Requests",
          to: "/settings/offlineRequests"
        },
        {
          icon: "mdi-chart-tree",
          key: "specialReports",
          title: "Special Reports",
          to: "/specialReports",
          subtitle: "Preformatted custom reports"
        },
        {
          icon: "mdi-bullhorn-outline",
          key: "announcements",
          title: "Alerts",
          to: "/announcements",
          subtitle: "Reminders, alerts and more"
        }
      ],
      notifications: [],
      miniVariant: true,
      overlay: true,
      localForageOverrides: {
        system: ["waterYear", "availableWaterYears", "offlinePushRequests"],
        omsettings: ["omOfYear"]
      }
    }
  },
  head () {
    return {
      title: `${this.$auth.user.data.association.name}`
    }
  },
  computed: {
    loadCount: {
      get () {
        return this.$store.state.system.loadCount
      },
      set (value) {
        this.$store.commit("system/SET_LOADCOUNT", value)
      }
    },
    loadStatus: {
      get () {
        return this.$store.state.system.loadStatus
      },
      set (value) {
        this.$store.commit("system/SET_LOADSTATUS", value)
      }
    },
    storeState () {
      return this.$store.state
    },
    isOnline () {
      return this.$nuxt.isOnline
    },
    fubound () {
      return this.$store.state.farmunits.bound
    },
    loadStores () {
      return this.$store.state.system.loadStores
    },
    title () {
      return this.$auth.user.data.association.acronym
    },
    currentDate () {
      return this.date
    },

    menuItems () {
      const menuItems = this.items

      if (this.is_ditchrider) {
        return menuItems.filter(ma =>
          [
            "dock",
            "orders",
            "contacts",
            "canalSchedule",
            "maintenanceRequests",
            "dashboard",
            "dailycfs"
          ].includes(ma.key)
        )
      }

      if (this.is_readonly) {
        return menuItems.filter(ma =>
          [
            "dock",
            "orders",
            "contacts",
            "canalSchedule",
            "maintenanceRequests",
            "dashboard"
          ].includes(ma.key)
        )
      }
      return menuItems
    },

    logo () {
      let logo = "/_nuxt/assets/logo.png"

      if (this.$auth.user.data.association.logo) {
        logo = this.$auth.user.data.association.logo

        if (logo && !logo.includes("http")) {
          return `//${logo}`
        }
      }

      return logo
    },

    allowActiveDialog () {
      let allow = true
      if (this.wcopen && this.path === "contacts") {
        allow = false
      }
      return allow
    },
    path () {
      return this.$store.state.system.activeView
    },
    customComponent () {
      return this.$store.state.system.customComponent
    },
    permissions () {
      return this.$store.getters["system/permissions"]
    },
    roles () {
      return this.$store.getters["system/roles"]
    },
    is_ditchrider () {
      return this.roles.includes("ditchrider") || !this.is_admin
    },
    is_admin () {
      return this.roles.includes("admin") || this.roles.includes("superAdmin")
    },
    is_support () {
      return this.roles.includes("support")
    },
    waterYear () {
      return this.$store.getters["system/getWaterYear"]
    },
    waterYears () {
      return this.$store.getters["system/getAllWaterYears"]
    },
    latestWaterYear () {
      return this.$store.getters["system/getLatestWaterYear"]
    },
    is_readonly () {
      return this.roles.includes("readonly")
    },
    syncStats () {
      return this.$store.getters["syncStats/find"](1)
    },
    latestFetch () {
      return this.syncStats.hasOwnProperty("fetch") &&
        this.syncStats.fetch.date &&
        this.syncStats.fetch.date !== ""
        ? this.syncStats.fetch
        : null
    },
    latestPush () {
      return this.syncStats.hasOwnProperty("push") &&
        this.syncStats.push.date &&
        this.syncStats.push.date !== ""
        ? this.syncStats.push
        : null
    }
  },
  watch: {
    storeState: {
      async handler () {
        await this.saveStatetoLocalForage()
      },
      deep: true
    }
  },
  beforeDestroy () {
    // prevent memory leak
    clearInterval(this.interval)
  },
  created () {
    // update the time every second
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat(navigator.language, {
        hour: "numeric",
        minute: "numeric",
        hourCycle: "h23"
      }).format()
    }, 1000)
  },
  beforeMount () {
    this.isClient = false
  },
  async mounted () {
    if (process.env.NODE_ENV === "production") {
      navigator.serviceWorker.onmessage = (e) => {
        if (e.data === "afterPrecache") {
          this.afterPrecache = true
        }
      }
    } else {
      this.afterPrecache = true
    }
    this.loadCount = Object.values(this.storeState).length + 5
    this.loadStatus = 0
    this.$nextTick(() => {
      this.$nuxt.$loading.start()
    })
    await this.getLocalForageToState()

    await this.$store.dispatch("syncStats/show", 1)
    await this.$store.dispatch("syncHistory/list")

    this.loadStatus++
    await this.checkOMSettings(this.$store.state.system.waterYear)
    this.loadStatus++
    this.splitWaitNoWait()
    this.loadStatus++
    await this.fetchLatestDataWait()
    this.loadStatus++
    this.fetchLatestDataNoWait()
    this.loadStatus++
    $nuxt.$loading.finish()
    this.loadCount = 0
    this.loadStatus = 0
    this.isClient = true
    // TODO: disable all API requests when OFFLINE

    // this.overlay = false
  },
  methods: {
    async getLocalForageToState () {
      for (const [key, value] of Object.entries(this.storeState)) {
        if (this.localForageOverrides.hasOwnProperty(key)) {
          for (const overrideKey of this.localForageOverrides[key]) {
            const overrideValue = await this.$localForage.getItem(
              key + "-" + overrideKey
            )
            if (overrideValue) {
              const override = { key: overrideKey, value: overrideValue }
              await this.$store.commit(`${key}/SET_DYNAMIC`, override)
              this.loadStatus++
            }
          }
        }
        if (value.hasOwnProperty("all")) {
          const all = await this.$localForage.getItem(key)
          if (all) {
            await this.$store.commit(`${key}/REPLACE_ALL`, all)
            this.loadStatus++
          }
        }
      }
    },
    async saveStatetoLocalForage () {
      for (const [key, value] of Object.entries(this.storeState)) {
        if (this.localForageOverrides.hasOwnProperty(key)) {
          for (const overrideKey of this.localForageOverrides[key]) {
            await this.$localForage.setItem(
              key + "-" + overrideKey,
              value[overrideKey]
            )
          }
        }
        if (value.hasOwnProperty("all")) {
          await this.$localForage.setItem(key, value.all)
        }
      }
    },
    splitWaitNoWait () {
      this.noWaitStores = []
      this.waitStores = []
      for (const store in this.loadStores) {
        const lastUpdated =
          this.$store.getters[`${this.loadStores[store]}/lastUpdated`]
        if (lastUpdated) {
          this.noWaitStores.push(store)
        } else {
          this.waitStores.push(store)
        }
      }
    },
    async fetchLatestDataWait () {
      for (const store in this.waitStores) {
        await this.$store.dispatch(`${this.loadStores[this.waitStores[store]]}/list`)
      }
    },
    fetchLatestDataNoWait () {
      for (const store in this.noWaitStores) {
        this.$store.dispatch(`${this.loadStores[this.noWaitStores[store]]}/list`)
      }
    },
    navigateTo (link) {
      return this.$router.push(link)
    },
    // mapUri () {
    //   const uri = this.$route.name
    //   if (uri && uri !== "" && uri !== "index") {
    //     return uriToPath[uri] ? uriToPath[uri] : `${uri}/list`
    //   } else {
    //     return null
    //   }
    // },
    setSearchValue (value) {
      this.searchValue = value
    },
    logout () {
      this.$auth.logout().then(() => this.$router.push("/login"))
    },
    async setWaterYear (value) {
      await this.$store.dispatch("system/setWaterYear", value)
      const omsetting = await this.checkOMSettings(value)

      if (omsetting) {
        await this.$store.dispatch("omsettings/set_om_of_year", omsetting, {
          root: true
        })
        // await this.refresh()
      }
    },
    // async refresh () {
    //   const storePath = this.mapUri()

    //   if (storePath) {
    //     this.$nuxt.$loading.start()
    //     await this.$store.dispatch(storePath)
    //     this.$nuxt.$loading.finish()
    //   }
    // },
    async checkOMSettings (value = null) {
      const currentYear = value || this.latestWaterYear

      const om = Object.values(this.$store.state.omsettings.all).find(
        item => item.year === currentYear
      )
      if (!om) {
        const errorObject = {
          message: `OM Settings for ${currentYear} has not been set yet! Please add these in the O&M Settings page first!`
        }

        await this.$store.dispatch("omsettings/set_om_of_year", false)
        this.$store.commit("snackbar/showMessage", { content: errorObject, color: "error" })
        await this.$router.push("/omsettings")
        return false
      } else {
        await this.$store.dispatch("omsettings/set_om_of_year", om)
      }

      return om
    },
    addObservation () {
      this.$store.commit("observations/updateBound", {
        key: "observed_at",
        value: this.$moment().format("YYYY-MM-DD")
      })
      this.$store.commit("observations/updateBound", {
        key: "observed_on",
        value: this.$moment().format("HH:mm")
      })
      this.addObservationDialog = true
    },
    showObservations () {
      this.observationsDialogDisplays = "List"
    },
    closeCreateObservation () {
      this.addObservationDialog = false
      this.$store.commit("observations/FLUSH_ACTIVE")
    }
  }
}
</script>
<style>
.border-right {
  border-right: 1px solid;
  border-color: rgba(0, 0, 0, 0.12);
}

.systembar {
  text-align: center;
  border-radius: 5px;
  position: sticky;
  top: 64px;
  z-index: 3;
}

.sync-bar {
  position: relative;
  top: 0;
  height: 55px !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.systembar-text {
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #fff;
  text-transform: capitalize;
}

.sync-bar-text {
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #000000;
  text-transform: capitalize;
}

.pointer {
  cursor: pointer;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.slide-fade-enter-active {
  transition: all 0.8s ease-in-out;
}

.slide-fade-leave-active {
  transition: all 0.8s ease-in-out;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

.page-height {
  height: calc(100vh - 120px);
}
</style>
