import globalActions from "../api/GlobalActions.js"
import globalMutations from "../api/GlobalMutations.js"
import globalGetters from "../api/GlobalGetters.js"

export const state = () => ({
  isStale: true,
  latest_fetch: "",
  type: "maintenanceRequests",
  pageLayout: "StructureTable",
  canEdit: false,
  canView: false,
  effectedByYear: false,
  yearFields: [],
  aliasField: "id",
  modelLabels: {
    single: "Maintenance Request",
    plural: "Maintenance Requests"
  },
  filterHideOverrides: [
    "audits",
    "irrigated_acres",
    "ownershipHistory",
    "producerHistory"
  ],
  relations: [
    {
      name: "audits",
      translate: "audits",
      label: "Audits",
      value: "audits",
      storename: "audits",
      yearFields: [],
      export: false
    },
    {
      name: "district",
      translate: "district",
      value: "districts",
      label: "District",
      storename: "districts",
      yearFields: []
    },
    {
      name: "files",
      translate: "files",
      label: "Files",
      value: "files",
      storename: "files",
      yearFields: []
    }
  ],
  searchFields: ["canal"],
  all: {},
  reference: {
    /* for showing paginated resources ... needs a bit more work. */
  },
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: "name",
    page: 1,
    sortDesc: []
  },
  print_path: "",
  search: null,
  total: 0,
  print: false,
  export: false,
  bound: {},
  filter: {},
  quickFilters: [
    {
      label: "District",
      component: "SearchProducersByDistrict",
      name: "districts", // do we need this?
      relationName: "district",
      field: "name",
      description: "Find orders by one or more districts",
      order: 2
    }
  ],
  stub: {
    audits: []
  },
  include: "files,audits",
  headers: {},
  notifications: {}
})

// Mutations ==================

export const mutations = {
  ...globalMutations
}

// Actions ====================

export const actions = {
  ...globalActions
}

// Getters ====================

export const getters = {
  ...globalGetters
}
