import globalActions from "../api/GlobalActions.js"
import globalMutations from "../api/GlobalMutations.js"
import globalGetters from "../api/GlobalGetters.js"

export const state = () => ({
  isStale: true,
  latest_fetch: "",
  type: "addressHistory",
  canEdit: false,
  canView: false,
  effectedByYear: false,
  yearFields: [],
  aliasField: "id",
  modelLabels: {
    single: "Address History",
    plural: "Address History"
  },
  relations: [
    {
      name: "contact",
      translate: "contact",
      label: "Contact",
      value: "contact",
      yearFields: []
    },
    {
      name: "user",
      translate: "user",
      label: "User",
      value: "user",
      yearFields: []
    }
  ],
  all: {},
  reference: {},
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: "updated_at",
    page: 1,
    sortDesc: ["updated_at"]
  },
  print_path: "",
  total: 0,
  print: false,
  export: false,
  search: null,
  bound: {
    id: "",
    user: {},
    address: "",
    address_1: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    updated_at: ""
  },
  stub: {
    user: {}
  },
  filter: {},
  quickFilters: [],
  include: "",
  headers: {},
  notifications: {},
  searchFields: ["address"]
})

export const mutations = {
  ...globalMutations
}

export const actions = {
  ...globalActions
}

export const getters = {
  ...globalGetters
}
