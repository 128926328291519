const middleware = {}

middleware['auth-admin'] = require('../middleware/auth-admin.js')
middleware['auth-admin'] = middleware['auth-admin'].default || middleware['auth-admin']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['isAdmin'] = require('../middleware/isAdmin.js')
middleware['isAdmin'] = middleware['isAdmin'].default || middleware['isAdmin']

middleware['preappliedFilters'] = require('../middleware/preappliedFilters.js')
middleware['preappliedFilters'] = middleware['preappliedFilters'].default || middleware['preappliedFilters']

middleware['waterYears'] = require('../middleware/waterYears.js')
middleware['waterYears'] = middleware['waterYears'].default || middleware['waterYears']

export default middleware
