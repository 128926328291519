import Errors from "@/Errors"

export default ({ $axios, redirect, app, store, $bugsnag }, inject) => {
  $axios.onError((error) => {
    if (error.response === undefined) {
      throw error
    }
    const errorResponse = new Errors().setAndParse(error)
    store.commit("snackbar/showMessage", { content: errorResponse, color: "error" })

    $bugsnag.notify(errorResponse.message)
    throw error
  })
}
