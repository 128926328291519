import createRepository from "~/api/Api.js"

export default (ctx, inject) => {
  const repositoryWithAxios = createRepository(ctx.$axios, ctx.store)

  /* sample: resourceName: respositoryWithAxios("resourceName")
     * This is accessible in the store with the syntax:
     * this.$repositories.resourcename
     */
  const repositories = {
    announcements: repositoryWithAxios("announcements"),
    audits: repositoryWithAxios("audits"),

    getWaterYears: repositoryWithAxios("getWaterYears"),
    forgotPassword: repositoryWithAxios("forgotPassword"),
    memberHeaders: repositoryWithAxios("memberHeaders"),
    getNewOrderNumber: repositoryWithAxios("getNewOrderNumber"),

    farmunits: repositoryWithAxios("farmunits"),
    farmunitOwners: repositoryWithAxios("farmunits", "owners"),
    farmunitUsages: repositoryWithAxios("farmunits", "usages"),
    farmunitProducers: repositoryWithAxios("farmunits", "producers"),
    farmunitDiversionPoints: repositoryWithAxios("farmunits", "diversionPoints"),

    districts: repositoryWithAxios("districts"),
    districtDiversionPoints: repositoryWithAxios("districts", "diversionPoints"),

    canals: repositoryWithAxios("canals"),
    dailycfs: repositoryWithAxios("dailycfs"),
    dailycfsFilters: repositoryWithAxios("dailycfsFilters"),
    orders: repositoryWithAxios("orders"),
    usages: repositoryWithAxios("usages"),

    flags: repositoryWithAxios("flags"),
    flagContacts: repositoryWithAxios("flags", "contacts"),

    contacts: repositoryWithAxios("contacts"),
    contactNotes: repositoryWithAxios("contacts", "notes"),
    contactOwns: repositoryWithAxios("contacts", "owns"),
    contactProduces: repositoryWithAxios("contacts", "produces"),
    contactDiversionPoints: repositoryWithAxios("contacts", "diversionPoints"),
    contactsFlags: repositoryWithAxios("contacts", "flags"),

    producerOrders: repositoryWithAxios("producers", "orders"),
    producerUsages: repositoryWithAxios("producers", "usages"),

    diversionPointOrders: repositoryWithAxios("diversionPoints", "orders"),
    diversionPointRevokedOrders: repositoryWithAxios("diversionPoints", "revokedOrders"),
    diversionPointUsages: repositoryWithAxios("diversionPoints", "usages"),
    diversionPoints: repositoryWithAxios("diversionPoints"),

    users: repositoryWithAxios("users"),
    notes: repositoryWithAxios("notes"),

    files: repositoryWithAxios("files"),

    maintenanceRequests: repositoryWithAxios("maintenanceRequests"),

    observations: repositoryWithAxios("observations"),
    omsettings: repositoryWithAxios("omsettings"),
    uploadContacts: repositoryWithAxios("uploadContacts"),
    uploadFarmunits: repositoryWithAxios("uploadFarmunits"),
    uploadCanals: repositoryWithAxios("uploadCanals"),
    uploadDiversionpoints: repositoryWithAxios("uploadDiversionpoints"),
    userNotifications: repositoryWithAxios("users", "notifications"),
    filters: repositoryWithAxios("filters"),

    logo: repositoryWithAxios("logo"),
    appInfo: repositoryWithAxios("appInfo"),

    irrigationTypes: repositoryWithAxios("irrigationTypes"),
    cropTypes: repositoryWithAxios("cropTypes"),

    synchronizations: repositoryWithAxios("synchronizations"),
    syncStats: repositoryWithAxios("syncStats"),
    syncHistory: repositoryWithAxios("syncHistory"),
    offlineRequests: repositoryWithAxios("offlineRequests"),

    // reports
    waterMasterCurrentFuture: repositoryWithAxios("waterMasterCurrentFuture"),
    ditchRiderUpcoming: repositoryWithAxios("ditchRiderUpcoming"),
    activeDistrictUsage: repositoryWithAxios("activeDistrictUsage"),
    cumulativeUsageByProducer: repositoryWithAxios("cumulativeUsageByProducer"),
    cumulativeUsageByOwner: repositoryWithAxios("cumulativeUsageByOwner"),
    canalUsageByDistrict: repositoryWithAxios("canalUsageByDistrict"),
    activeUsageByCanal: repositoryWithAxios("activeUsageByCanal"),
    farmunitDirectory: repositoryWithAxios("farmunitDirectory"),
    producerUsage: repositoryWithAxios("producerUsage"),
    yearlyCropCensus: repositoryWithAxios("yearlyCropCensus"),
    projectMaintenance: repositoryWithAxios("projectMaintenance"),
    reportDailyCanalFlows: repositoryWithAxios("reportDailyCanalFlows"),
    reportObservations: repositoryWithAxios("reportObservations")
  }

  inject("repositories", repositories)
}
