import { normalize } from "normalizr"
import localForage from "localforage"
import { v4 as uuidv4 } from "uuid"
import moment from "moment"

export default {
  async set_active ({ commit, state, getters, dispatch, rootState }, id) {
    await commit("active", id)
  },
  MERGEHEADERS ({ commit, state }, payload) {
    const schema = Object.values(payload)
    schema.forEach((item) => {
      commit("MERGEHEADERS", item)
    })
  },

  /**
   *
   * MERGEHEADERS builds out the schema for each module found in the store.
   * these headers are kept in the db and a built out a JSON structure in the laravel migrations.
   */

  UpdateBound ({ commit, state }, payload) {
    const schema = Object.values(payload)
    const defaultBound = state.bound

    schema.forEach((item) => {
      const p = {}
      if (item.type === "checkbox") {
        p.key = item.field
        p.value = defaultBound[item.field] ? defaultBound[item.field] : 0
      } else {
        p.key = item.field
        p.value = defaultBound[item.field] ? defaultBound[item.field] : ""
      }
      commit("updateBound", p)
    })
  },
  MERGENOTIFICATIONS ({ commit, state }, payload) {
    // commit("FLUSHNOTIFICATIONS");

    commit("MERGENOTIFICATIONS", payload)
    // let schema = Object.values(payload);
    // schema.forEach(item => {
    //   commit("MERGENOTIFICATIONS", item);
    // });
  },
  MERGEFILTERS ({ commit, state }, payload) {
    const newSchema = {}
    for (const key in payload) {
      const value = payload[key]

      let newValue
      if (value === null) {
        newValue = ""
      } else {
        newValue = value
      }

      newSchema[key] = newValue
      // commit("updateFilter", { key, newValue });
    }

    commit("MERGEFILTERS", newSchema)
  },

  set_reference ({ commit, state }, obj) {
    commit("SET_REFERENCE", obj)
  },
  set_total ({ commit, state }, total) {
    commit("totalItems", total)
  },

  async list ({ commit, state, getters, dispatch }) {
    if (!navigator.onLine) {
      return
    }
    const params = getters.params
    if (params.hasOwnProperty("print")) {
      delete params.print
    }
    if (params.hasOwnProperty("export")) {
      delete params.export
    }
    // if params has print/export, remove it

    const response = await this.$repositories[state.type].index(params)
    if (response.hasOwnProperty("error")) {
      return
    }

    let data = response.data

    if (!Array.isArray(data)) {
      data = Object.values(data)
    }

    const ids = data.map(item => item.id)
    const page = state.page

    const { entities } = normalize(data, [
      this.$relationships[state.type]
    ])
    /* if (state.type === "canals") {
      console.log(data)
    } */
    commit("MERGE", entities[state.type])

    const obj = {
      page,
      ids
    }

    dispatch("set_reference", obj)

    commit("SET_FETCH_DATE")
  },

  async subList ({ commit, state, getters, dispatch, rootState }, payload) {
    if (!navigator.onLine) {
      return
    }
    const type = payload.type
    const id = payload.id
    const child = payload.child

    const waterYear = rootState.system.waterYear

    // if (navigator.onLine) {
    const results = await this.$repositories[type].subIndex(id, { waterYear })
    if (results.hasOwnProperty("error")) {
      return
    }

    dispatch(`${child}/subListMerge`, results.data, {
      root: true
    })

    const ids = results.data.map(item => item.id)
    const page = 1
    const obj = {
      page,
      ids
    }
    /* Adds a encyclopedia that maps pages to index */
    dispatch(`${child}/set_reference`, obj, { root: true })
    dispatch(`${child}/set_total`, results.data.length, { root: true })
  },

  subListMerge ({ commit, state, getters, dispatch }, payload) {
    const { entities } = normalize(payload, [this.$relationships[state.type]])
    //    commit("MERGE", entities[state.type]);
    Object.entries(entities).forEach(([key, value]) =>
      dispatch(`${key}/merge`, value, {
        root: true
      })
    )
  },

  merge ({ commit, state, dispatch }, payload) {
    console.log("globalActions MERGE", payload)
    commit("MERGE", payload)
  },

  async create ({ commit, state, getters, dispatch, rootState }, payload) {
    payload.waterYear = rootState.system.waterYear

    let results = {}

    if (navigator.onLine) {
      results = await this.$repositories[state.type].create(payload)
    } else {
      console.log("OFFLINE, CREATE")
      console.log({ payload })
      const requestId = uuidv4()
      const obj = {
        type: "create",
        state: state.type,
        requestId,
        payload,
        requestDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
      }

      payload.user = rootState.auth.user.data.id

      const headers = getters.headers.map(i => i.field)

      const updatedResource = {}
      headers.forEach((i) => {
        if (payload.hasOwnProperty(i)) {
          updatedResource[i] = payload[i]
        } else {
          updatedResource[i] = ""
        }
      })

      await commit("system/PUT_OFFLINE_REQUEST", obj, { root: true })

      dispatch("snackbar/showMessage", {
        content: {
          message: "Your request is added to the synchronization queue. Do not forget to sync your requests when you're back online!",
          status: 200
        },
        color: "success"
      }, {
        root: true
      })
      results = { data: { id: requestId, ...updatedResource } }
    }

    if (results) {
      const { entities } = normalize(
        [results.data],
        [this.$relationships[state.type]]
      )

      commit("MERGE", entities[state.type])

      return results
    }
  },

  async subCreate ({ commit, state, getters, dispatch, rootState }, payload) {
    const type = payload.type
    const id = payload.id
    const resource = payload.resource
    resource.waterYear = rootState.system.waterYear

    let results = {}

    if (navigator.onLine) {
      results = await this.$repositories[type].subCreate(id, resource)
      console.log("results in globalActions", results)
    } else {
      if (payload.parentType) {
        resource[payload.parentType] = id
      }
      resource.user = rootState.auth.user.data.id

      const headers = getters.headers.map(i => i.field)

      const updatedResource = {}
      headers.forEach((i) => {
        if (resource.hasOwnProperty(i)) {
          updatedResource[i] = resource[i]
        } else {
          updatedResource[i] = ""
        }
      })

      const requestId = uuidv4()
      const obj = {
        type: "subCreate",
        state: state.type,
        requestId,
        payload: { ...payload, resource },
        requestDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
      }

      await commit("system/PUT_OFFLINE_REQUEST", obj, { root: true })

      dispatch("snackbar/showMessage", {
        content: {
          message: "Your request is added to the synchronization queue. Do not forget to sync your requests when you're back online!",
          status: 200
        },
        color: "success"
      }, {
        root: true
      })

      results = { data: { id: requestId, ...updatedResource } }
    }

    if (!results || results.hasOwnProperty("error")) {
      return false
    }
    console.log(this.$relationships[state.type])
    const { entities } = normalize(
      [results.data],
      [this.$relationships[state.type]]
    )

    dispatch(`${state.type}/merge`, entities[state.type], { root: true })

    return results
  },

  async show ({ commit, state, getters, dispatch, rootState }, id) {
    // commit("active", id)
    if (!navigator.onLine) {
      commit("active", id)
      return
    }
    // This is needed to include relations
    const params = getters.showParams

    const results = await this.$repositories[state.type].show(id, params)

    const { entities } = normalize(
      [results.data],
      [this.$relationships[state.type]]
    )
    commit("MERGE", entities[state.type])

    commit("active", id)
  },
  async update (
    { commit, state, getters, dispatch, rootState },
    { id, payload }
  ) {
    payload.waterYear = rootState.system.waterYear

    let results = {}

    // IF ITEM BEING EDITED IS HASN'T BEEN PUSHED CHECK AND ONLY UPDATE LOCALLY
    let itemCreateOffline = rootState.system.offlinePushRequests.find((i) => {
      return (
        (i.type === "subCreate" || i.type === "create") && i.requestId === id
      )
    })
    if (itemCreateOffline) {
      itemCreateOffline = JSON.parse(JSON.stringify(itemCreateOffline))
      Object.entries(payload).forEach(([key, value]) => {
        if (itemCreateOffline.type === "subCreate") {
          itemCreateOffline.payload.resource[key] = value
        } else if (itemCreateOffline.type === "create") {
          itemCreateOffline.payload[key] = value
        }
      })

      await commit("system/UPDATE_OFFLINE_REQUEST", itemCreateOffline, {
        root: true
      })
      let newPayload = { id }
      if (itemCreateOffline.type === "subCreate") {
        newPayload = { ...newPayload, ...itemCreateOffline.payload.resource }
      } else if (itemCreateOffline.type === "create") {
        newPayload = { ...newPayload, ...itemCreateOffline.payload }
      }
      results = { data: { ...newPayload } }
      dispatch("snackbar/showMessage", {
        content: {
          message: "Your request is added to the synchronization queue. Do not forget to sync your requests when you're back online!",
          status: 200
        },
        color: "success"
      }, {
        root: true
      })
    } else if (navigator.onLine) {
      results = await this.$repositories[state.type].update({
        id,
        payload
      })
    } else {
      const requestId = uuidv4()
      const obj = {
        type: "update",
        state: state.type,
        requestId,
        payload: { id, payload },
        requestDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
      }
      await commit("system/PUT_OFFLINE_REQUEST", obj, { root: true })

      dispatch("snackbar/showMessage", {
        content: {
          message: "Your request is added to the synchronization queue. Do not forget to sync your requests when you're back online!",
          status: 200
        },
        color: "success"
      }, {
        root: true
      })
      payload.id = id
      results = { data: { ...payload } }
    }

    if (results.hasOwnProperty("error")) {
      return false
    }
    const { entities } = normalize(
      [results.data],
      [this.$relationships[state.type]]
    )
    // commit("MERGE", entities[state.type]);
    Object.entries(entities).forEach(([key, value]) =>
      dispatch(`${key}/merge`, value, {
        root: true
      })
    )
    return true
  },
  async bulkUpdate (
    { commit, state, getters, dispatch, rootState },
    payloadPerId
  ) {
    // payloadPerId.waterYear = rootState.system.waterYear

    if (navigator.onLine) {
      const response = await this.$repositories[state.type].bulkUpdate(
        payloadPerId
      )
      if (response.hasOwnProperty("error")) {
        return
      }

      let data = response.data

      if (!Array.isArray(data)) {
        data = Object.values(data)
      }

      const { entities } = normalize(data, [
        this.$relationships[state.type]
      ])
      commit("MERGE", entities[state.type])
    } else {
      let createdOffline = false
      const resultsArr = []
      for (const id in payloadPerId) {
        const payload = payloadPerId[id]
        payload.waterYear = rootState.system.waterYear
        // IF ITEM BEING EDITED IS HASN'T BEEN PUSHED CHECK AND ONLY UPDATE LOCALLY
        let itemCreateOffline = rootState.system.offlinePushRequests.find((i) => {
          return (
            (i.type === "subCreate" || i.type === "create") && i.requestId === id
          )
        })
        if (itemCreateOffline) {
          itemCreateOffline = JSON.parse(JSON.stringify(itemCreateOffline))
          Object.entries(payload).forEach(([key, value]) => {
            if (itemCreateOffline.type === "subCreate") {
              itemCreateOffline.payload.resource[key] = value
            } else if (itemCreateOffline.type === "create") {
              itemCreateOffline.payload[key] = value
            }
          })

          await commit("system/UPDATE_OFFLINE_REQUEST", itemCreateOffline, {
            root: true
          })
          let newPayload = { id }
          if (itemCreateOffline.type === "subCreate") {
            newPayload = { ...newPayload, ...itemCreateOffline.payload.resource }
          } else if (itemCreateOffline.type === "create") {
            newPayload = { ...newPayload, ...itemCreateOffline.payload }
          }
          resultsArr.push({ ...newPayload })
        } else {
          const requestId = uuidv4()
          payload.id = Number(id)
          const obj = {
            type: "update",
            state: state.type,
            requestId,
            payload: { id, payload },
            requestDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
          }
          await commit("system/PUT_OFFLINE_REQUEST", obj, { root: true })
          createdOffline = true

          resultsArr.push({ ...payload })
        }
      }
      // console.log(resultsArr)
      const { entities } = normalize(resultsArr, [
        this.$relationships[state.type]
      ])
      commit("MERGE", entities[state.type])

      if (createdOffline) {
        const snackSettings = {
          color: "secondary",
          top: false,
          timeout: 2000,
          right: true
        }
      }
    }
    return true
  },
  async subUpdate (
    { commit, state, getters, dispatch, rootState },
    { parentId, subId, type, resource }
  ) {
    resource.waterYear = rootState.system.waterYear

    const results = await this.$repositories[type].subUpdate(
      parentId,
      subId,
      resource
    )

    if (results.hasOwnProperty("error")) {
      return false
    } else {
      const { entities } = normalize(
        [results.data],
        [this.$relationships[state.type]]
      )

      // commit("MERGE", entities[state.type]);
      Object.entries(entities).forEach(([key, value]) =>
        dispatch(`${key}/merge`, value, {
          root: true
        })
      )

      return results
    }
  },

  async detach (
    { commit, state, getters, dispatch },
    { parentId, childId, path, apiPath }
  ) {
    let results = {}

    if (navigator.onLine) {
      results = await this.$repositories[apiPath].subDelete(parentId, childId)
    } else {
      const requestId = uuidv4()
      const obj = {
        type: "detach",
        state: state.type,
        requestId,
        payload: {
          parentId,
          childId,
          path,
          apiPath
        },
        requestDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
      }
      await commit("system/PUT_OFFLINE_REQUEST", obj, { root: true })
    }

    if (!results.hasOwnProperty("error")) {
      // returns parent
      const { entities } = normalize(
        [results.data],
        [this.$relationships[state.type]]
      )
      // commit("MERGE", entities[state.type]);
      Object.entries(entities).forEach(([key, value]) =>
        dispatch(`${key}/merge`, value, {
          root: true
        })
      )
    }
  },
  resetFilter ({ commit, state, getters, dispatch }, filter) {
    commit("RESET_FILTER")
  },

  filter ({ commit, state, getters, dispatch }, filter) {
    commit("updateFilter", filter)
    dispatch("list")
  },
  async print ({ commit, state, getters, dispatch }, printedColumns) {
    const x = getters.params
    x.print = true
    x.export = false
    x.printedColumns = printedColumns
    const results = await this.$repositories[state.type].print(x)
    if (!results.hasOwnProperty("error")) {
      commit("SET_PRINT_PATH", results.data.path)
    }
  },
  async postPrint ({ commit, state, getters, dispatch, rootState }, payload) {
    payload.print = true
    payload.export = false
    payload.route = `${state.type}.index`
    payload.waterYear = rootState.system.waterYear
    const results = await this.$repositories[state.type].postPrint(payload)
    if (!results.hasOwnProperty("error")) {
      commit("SET_PRINT_PATH", results.data.path)
    }
  },
  async exportMerge ({ commit, state, getters, rootState }, payload) {
    payload.print = false
    payload.export = true
    payload.waterYear = rootState.system.waterYear
    payload.route = `${state.type}.index`

    const results = await this.$repositories[state.type].postPrint(payload)
    if (results.hasOwnProperty("error")) {
      return
    }

    commit("SET_PRINT_PATH", results.data.path)
  },
  async export ({ commit, state, getters, rootState }, payload) {
    payload.print = false
    payload.export = true
    payload.exporter_model = "App\\Exports\\RepeatExport"
    payload.route = `${state.type}.index`
    payload.waterYear = rootState.system.waterYear

    const results = await this.$repositories[state.type].postPrint(payload)
    if (results.hasOwnProperty("error")) {
      return
    }

    commit("SET_PRINT_PATH", results.data.path)
  },

  async subListPrint ({ commit, state, getters }, payload) {
    const type = payload.type
    const id = payload.id

    payload.print = true
    payload.export = false

    delete payload.id
    delete payload.type

    const results = await this.$repositories[type].subPrint(id, payload)
    if (results.hasOwnProperty("error")) {
      return false
    } else {
      commit("SET_PRINT_PATH", results.path)
    }
  },
  async subListExport ({ commit, state, getters }, payload) {
    const type = payload.type
    const id = payload.id

    payload.export = true
    payload.print = false

    delete payload.type
    delete payload.id

    const results = await this.$repositories[type].subExport(id, payload)
    if (results.hasOwnProperty("error")) {
      return
    }
    commit("SET_PRINT_PATH", results.path)
  },
  async showPrint ({ commit, state, getters, dispatch }, payload) {
    const id = payload.id

    payload.print = true
    payload.export = false

    delete payload.id

    const results = await this.$repositories[state.type].showPrint(id, payload)
    if (results.hasOwnProperty("error")) {
      return false
    } else {
      commit("SET_PRINT_PATH", results.data.path)
    }
  },
  async showExport ({ commit, state, getters, dispatch }, payload) {
    const id = payload.id

    payload.print = false
    payload.export = true

    delete payload.id

    const results = await this.$repositories[state.type].showPrint(id, payload)
    if (results.hasOwnProperty("error")) {
      return false
    } else {
      commit("SET_PRINT_PATH", results.data.path)
    }
  },
  async showExportMerge ({ commit, state, getters, dispatch }, payload) {
    const id = payload.id

    payload.print = false
    payload.export = true
    payload.exporter_model = "App\\Exports\\SingleWithRelsExport"

    delete payload.id

    const results = await this.$repositories[state.type].showPrint(id, payload)
    if (results.hasOwnProperty("error")) {
      return false
    } else {
      commit("SET_PRINT_PATH", results.data.path)
    }
  },
  async search ({ commit, state, getters, dispatch }, params) {
    let results = {}

    if (navigator.onLine) {
      results = await this.$repositories[state.type].index(params)
    } else {
      results = await localForage.getItem(`state.${state.type}`)
      if (!results || !results.hasOwnProperty("data")) {
        results = { data: [] }
      }
    }

    if (results.hasOwnProperty("error")) {
      return
    }

    results.data.map(item => item.id)

    const { entities } = normalize(results.data, [
      this.$relationships[state.type]
    ])

    Object.entries(entities).forEach(([key, value]) =>
      dispatch(`${key}/merge`, value, {
        root: true
      })
    )
  }
}
