import globalActions from "../api/GlobalActions.js"
import globalMutations from "../api/GlobalMutations.js"
import globalGetters from "../api/GlobalGetters.js"

export const state = () => ({
  isStale: true,
  latest_fetch: "",
  type: "canals",
  pageLayout: "StructureTable",
  inlineEdit: true,
  canEdit: false,
  canView: false,
  effectedByYear: false,
  yearFields: [],
  filterOverrides: ["audits"],
  aliasField: "name",
  modelLabels: {
    single: "Canal",
    plural: "Canals"
  },
  relations: [
    {
      name: "audits",
      translate: "audits",
      label: "Audits",
      value: "audits",
      storename: "audits",
      yearFields: [],
      export: false
    },
    {
      name: "farmunits",
      translate: "farmunits",
      value: "farmunits",
      label: "Farm Unit",
      storename: "farmunits",
      yearFields: []
    },
    {
      name: "district",
      translate: "district",
      value: "districts",
      label: "District",
      storename: "districts",
      yearFields: [],
      export: true
    },
    {
      name: "orders",
      translate: "orders",
      value: "orders",
      label: "Running CFS",
      storename: "orders",
      yearFields: [],
      export: true
    }
    // {
    //   name: "maintenanceRequests",
    //   translate: "maintenanceRequests",
    //   value: "maintenanceRequests",
    //   storename: "maintenanceRequests",
    //   yearFields: []
    // },
    // {
    //   name: "owners",
    //   translate: "owners",
    //   value: "owners",
    //   label: "Owners",
    //   storename: "contacts",
    //   yearFields: []
    // },
    // {
    //   name: "producers",
    //   translate: "producers",
    //   value: "producers",
    //   label: "Producers",
    //   storename: "contacts",
    //   yearFields: []
    // }
  ],
  all: {},
  reference: {
    /* for showing paginated resources ... needs a bit more work. */
  },
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: "order",
    page: 1,
    sortDesc: []
  },
  print_path: "",
  search: null,
  searchFields: ["name"],
  total: 0,
  print: false,
  export: false,
  bound: {},
  filter: {},
  quickFilters: [],
  stub: {
    audits: [],
    farmunits: [],
    maintenanceRequests: [],
    district: []
  },
  // members are called contractors on the server
  include: "district,audits",
  headers: {},
  notifications: {}
})

// Mutations ==================

export const mutations = {
  ...globalMutations
}

// Actions ====================

export const actions = {
  ...globalActions
}

// Getters ====================

export const getters = {
  tracked (state) {
    const collected = {}
    for (const index in state.all) {
      if (state.all[index].track_dcf) {
        collected[index] = state.all[index]
      }
    }
    return collected
  },
  ...globalGetters
}
