import { resolveRelations } from "./Helpers"
import {
  resolveFilters,
  filterRelationLabels,
  resolveSavedFilters
} from "@/config/savedFilters"

export default {
  standardized (state, getters) {
    const all = Object.values(state.all)
    return all.map(item => getters.find(item))
  },
  getAll (state) {
    return state.all
  },
  lastUpdated (state, getters) {
    let lastUpdated = ""
    const all = Object.values(state.all)
    if (all.length > 0) {
      if (all[0].hasOwnProperty("last_updated_date")) {
        const dates = all.map(i => i.last_updated_date)
        dates.sort()
        dates.reverse()
        lastUpdated = dates[0]
      }
    }
    return lastUpdated
  },
  getPage: state => (page) => {
    return Object.values(state.all).slice(
      (page - 1) * state.pagination.itemsPerPage,
      page * state.pagination.itemsPerPage
    )
  },
  find: (state, getters, rootState) => (item) => {
    let id = item
    if (typeof id === "object") {
      id = item.id
    }
    return state.all[id]
      ? resolveRelations(state.all[id], state.relations, rootState)
      : false
  },

  active (state, getters, rootState) {
    return state.active
      ? resolveRelations(state.all[state.active], state.relations, rootState)
      : false
  },
  headers (state, getters, rootState) {
    let headers = []
    if (state.hasOwnProperty("type")) {
      if (rootState.memberheaders.all.hasOwnProperty(state.type)) {
        headers = Object.values(rootState.memberheaders.all[state.type].schema)
      }
    }
    return headers
  },
  printHeaders (state, getters, rootState) {
    let headers = []
    if (state.hasOwnProperty("type")) {
      if (rootState.memberheaders.all.hasOwnProperty(state.type)) {
        headers = Object.values(rootState.memberheaders.all[state.type].schema)
      }
    }
    return headers.filter(h => h.exportable)
  },
  allHeaders (state, getters, rootState) {
    const headers = getters.headers

    const filtered = headers.filter(function (obj) {
      return obj.used
    })
    return filtered
  },

  visibleHeaders (state, getters) {
    const headers = getters.headers
    const filtered = headers.filter(function (obj) {
      return obj.display && obj.used
    })
    return filtered
  },
  publicHeaders (state, getters) {
    const headers = getters.headers
    const filtered = headers.filter(function (obj) {
      return obj.display && obj.used && obj.protected
    })
    return filtered
  },
  tableHeaders (state, getters) {
    const headers = getters.headers
    const filtered = headers.filter(function (obj) {
      return obj.tableDisplay && obj.used
    })

    const ordered = filtered.sort(function (a, b) {
      return a.column - b.column
    })
    return ordered
  },
  createHeaders (state, getters) {
    const headers = getters.headers
    const filtered = headers.filter(function (obj) {
      if (obj.hasOwnProperty("createDisplay")) {
        return obj.createDisplay && obj.used
      } else {
        return obj.tableDisplay && obj.used
      }
    })

    const ordered = filtered.sort(function (a, b) {
      return a.column - b.column
    })
    return ordered
  },
  reportList (state) {},
  exportAttributes (state, getters) {
    const relationships = state.relations.map(({ name }) => name)

    const headers = getters.headers
    const filtered = headers.filter(function (obj) {
      return obj.used && obj.exportable && !relationships.includes(obj.field)
    })

    const ordered = filtered.sort(function (a, b) {
      return a.column - b.column
    })
    return ordered
  },
  quickFilters: state =>
    [...state.quickFilters].sort((a, b) => a.order - b.order),
  filterAttributes (state, getters) {
    const relationships = state.relations.map(({ name }) => name)

    const headers = getters.headers
    const filtered = headers.filter(function (obj) {
      return obj.used && obj.filterDisplay && !relationships.includes(obj.field)
    })

    const ordered = filtered.sort(function (a, b) {
      return a.column - b.column
    })
    return ordered
  },
  filterRelations (state, getters) {
    const headers = getters.headers
    const filtered = headers.filter(function (obj) {
      return (
        obj.name !== "addressHistory" &&
        obj.name !== "notes" &&
        obj.name !== "ownershipHistory"
      )
    })
    return filtered.map(({ name }) => name)
  },
  exportRelations (state) {
    return state.relations.filter(function (obj) {
      return obj.export
    })
  },
  params (state, getters, rootState) {
    const x = {}
    const lastUpdated = getters.lastUpdated
    if (lastUpdated) {
      x["filter_scope[lastUpdated]"] = lastUpdated
    }
    x.waterYear = rootState.system.waterYear
    if (state.latest_fetch !== undefined) {
      x.latest_fetch = state.latest_fetch
    }

    const { sortBy, descending } = state.pagination
    let sort = sortBy
    if (descending) {
      sort = "-" + sortBy
      x.sort = sort
    } else {
      x.sort = sort
    }

    x.page = state.page
    if (state.pagination.itemsPerPage) {
      x.limit = state.pagination.itemsPerPage
    }

    if (state.search) {
      const searchTerm = "filter_scope[search]"
      x[searchTerm] = state.search
    }

    // used to add in filter relations
    // eslint-disable-next-line
    let relationsToinclude = []

    const filter = state.filter
    for (const prop in filter) {
      if (prop === "exact") {
        Object.entries(filter[prop]).forEach(([key, value]) => {
          const exact = `filter_exact[${key}]`
          const arr = []
          let formattedValue

          if (typeof value === "object") {
            value = Object.values(value)
          }

          if (value.isArray) {
            value.forEach((v) => {
              arr.push(encodeURIComponent(v))
            })
            formattedValue = arr.join(",")
          } else {
            formattedValue = value
          }

          // if (typeof value === "boolean") {
          //   formattedValue = value;
          // } else {
          //   value.forEach(v => {
          //     arr.push(encodeURIComponent(v));
          //   });
          //   formattedValue = arr.join(",");
          // }
          x[exact] = formattedValue
        })
      } else if (prop === "relations") {
        // add each of these relations to the include!
        const without = []
        Object.entries(filter[prop]).forEach(([key, value]) => {
          const arr = []

          const filterLabel = state.relations.find((obj) => {
            return obj.name === key
          })
          relationsToinclude.push(filterLabel.translate)
          const exactRelation = `filter_exact[${filterLabel.translate}.id]`

          for (const [k, val] of Object.entries(value)) {
            const keyOfValue = k

            if (k === "with_rel") {
              const v = Object.values(val)
              v.forEach((item) => {
                arr.push(encodeURIComponent(item))
              })
              const formattedValue = arr.join(",")
              x[exactRelation] = formattedValue
            } else if (k === "without_rel") {
              const filterLabel = state.relations.find((obj) => {
                return obj.name === key
              })
              // const scopeName = `filter_scope[${k}]`
              // Object.entries(v).forEach(([k, v]) => {
              //   without.push(encodeURIComponent(v));
              // });

              const formattedScope = filterLabel.translate
              without.push(encodeURIComponent(formattedScope))
              // x[scopeName] = [];
            } else {
              const filterRelation = state.relations.find((obj) => {
                return obj.name === key
              })
              // let scopeName = `filter_scope[${k}]`;
              Object.entries(val).forEach(([k, v]) => {
                if (
                  ["orders", "usages"].includes(key) &&
                  keyOfValue === "attr_between_date"
                ) {
                  const dates = Object.values(v).join(",")
                  x[`filter_scope[${keyOfValue}_${key}]`] = `${k},${dates}`
                } else {
                  const exact = `filter_exact[${filterRelation.translate}.${k}]`
                  const arr = []
                  let formattedValue

                  if (typeof v === "object") {
                    v = Object.values(v)
                  }

                  if (v.isArray) {
                    v.forEach((val) => {
                      arr.push(encodeURIComponent(val))
                    })
                    formattedValue = arr.join(",")
                  } else {
                    formattedValue = v
                  }

                  x[exact] = formattedValue
                }
              })
            }
          }
        })
        if (without.length) {
          x["filter_scope[without_rel]"] = without.join("|")
        }
      } else if (prop !== "") {
        const scope = `filter_scope[${prop}]`
        const arr = []

        if (typeof filter[prop] === "object") {
          Object.entries(filter[prop]).forEach(([key, value]) => {
            arr.push(key)

            if (typeof value === "boolean") {
              arr.push(value)
            } else {
              Object.entries(value).forEach(([k, v]) => {
                arr.push(encodeURIComponent(v))
              })
            }
          })
        } else if (
          typeof filter[prop] === "boolean" ||
          typeof filter[prop] === "number" ||
          typeof filter[prop] === "string"
        ) {
          arr.push(filter[prop])
        }

        const formattedValue = arr.join(",")

        x[scope] = formattedValue
      }
    }

    if (Array.isArray(state.include) && state.include.length) {
      x.include = state.include.concat(relationsToinclude).join(",")
    } else if (state.include.length) {
      x.include = state.include
    }

    return x
  },
  modelLabels (state) {
    return state.modelLabels
  },
  quickfilters (state) {
    return resolveSavedFilters(state.type)
  },
  showParams (state, getters, rootState) {
    const x = {}

    const waterYear = rootState.system.waterYear
    const om = Object.values(rootState.omsettings.all).find(
      om => parseInt(om.year) === parseInt(waterYear)
    )

    const yearArr = [
      om ? om.water_year_start : `${waterYear}-01-01`,
      om ? om.water_year_end : `${waterYear}-12-31`
    ]

    for (const relation of Object.values(state.relations)) {
      if (
        relation.hasOwnProperty("yearFields") &&
        relation.yearFields.length > 0
      ) {
        const k = relation.hasOwnProperty("translate")
          ? relation.translate
          : relation.name
        for (const v of relation.yearFields) {
          x[`filter_exact[${k}.${v}]`] = yearArr
        }
      }
    }

    if (state.include.length > 1) {
      x.include = state.include
    }

    x.waterYear = rootState.system.waterYear

    return x
  },
  formattedFilters (state) {
    const filter = _.omit(state.filter, "relations")
    const list = []

    // loop filters
    // check if it is relations -> do relation things.
    // if not relations, run loop
    // for each
    // if exact push values
    // else resolve filter
    // create obj

    _.forEach(filter, function (v, k) {
      if (k === "exact") {
        Object.entries(v).forEach(([key, value]) => {
          let formattedValue = ""
          if (typeof value === "boolean") {
            formattedValue = value
          } else {
            const entryValue = []
            Object.entries(value).forEach(([y, x]) => {
              entryValue.push(x)
            })
            formattedValue = entryValue.join(",")
          }

          const obj = {
            value: formattedValue,
            type: k,
            key,
            relation: false,
            relationpath: ""
          }
          list.push(obj)
        })
      } else {
        Object.entries(v).forEach(([key, value]) => {
          const obj = {
            value: resolveFilters(k, Object.values(value)),
            type: k,
            key,
            relation: false,
            relationpath: ""
          }
          list.push(obj)
        })
      }
    })
    return list
  },

  filterRelationLabels (state, getters, rootState, rootGetters) {
    const filterRelationLabel = filterRelationLabels()
    const byState = filterRelationLabel.find(i => i.field === state.type)
    return byState
  },

  formattedRelationFilters (state, getters, rootState, rootGetters) {
    const filterRelationLabel = filterRelationLabels()
    if (state.hasOwnProperty("relations")) {
      const list = []
      const relations = state.relations

      _.forEach(relations, function (r, rk) {
        const relationName = rk

        const mappedPath = filterRelationLabel.find(
          obj => obj.field === relationName
        )

        _.forEach(r, function (v, k) {
          if (k === "with_rel" || k === "exact") {
            Object.entries(v).forEach(([key, value]) => {
              let formattedValue = ""

              if (typeof value === "boolean") {
                formattedValue = value
              } else {
                const temp = rootGetters[`${mappedPath.model}/find`](value)
                if (temp) {
                  const relationSchema = filterRelationLabel.find(
                    obj => obj.field === relationName
                  )

                  formattedValue = temp[relationSchema.itemText]
                } else {
                  formattedValue = value
                }
              }

              const obj = {
                value: formattedValue,
                type: k,
                key,
                relation: true,
                relationpath: relationName
              }
              list.push(obj)
            })
          } else if (k === "without_rel") {
            const obj = {
              value: "Missing",
              type: k,
              key: relationName,
              relation: true,
              relationpath: relationName
            }
            list.push(obj)
          } else {
            Object.entries(v).forEach(([key, value]) => {
              const obj = {
                value: resolveFilters(k, Object.values(value)),
                type: k,
                key,
                relation: true,
                relationpath: relationName
              }
              list.push(obj)
            })
          }
        })
      })
      return list
    } else {
      return {}
    }
  }
}
