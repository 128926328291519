import globalActions from "../api/GlobalActions.js"
import globalMutations from "../api/GlobalMutations.js"
import globalGetters from "../api/GlobalGetters.js"

export const state = () => ({
  isStale: true,
  latest_fetch: "",
  type: "flags",
  pageLayout: "StructureTable",
  canEdit: false,
  canView: false,
  effectedByYear: false,
  yearFields: [],
  aliasField: "name",
  modelLabels: {
    single: "Flag",
    plural: "Flags"
  },
  filterOverrides: ["audits"],
  relations: [
    {
      name: "audits",
      translate: "audits",
      label: "Audits",
      value: "audits",
      storename: "audits",
      yearFields: [],
      export: false
    },
    {
      name: "contacts",
      translate: "contacts",
      label: "Contacts",
      value: "contacts",
      storename: "contacts",
      yearFields: []
    }
  ],
  all: {},
  reference: {
    /* for showing paginated resources ... needs a bit more work. */
  },
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: "name",
    page: 1,
    sortDesc: []
  },
  print_path: "",
  search: null,
  searchFields: ["name"],
  total: 0,
  print: false,
  export: false,
  bound: {
    name: "",
    contacts: []
  },
  filter: {
    contacts: []
  },
  quickFilters: [],
  stub: {
    audits: [],
    contacts: []
  },
  // members are called contractors on the server
  include: "contacts,audits",
  headers: {},
  notifications: {}
})

// Mutations ==================

export const mutations = {
  ...globalMutations
}

// Actions ====================

export const actions = {
  ...globalActions
}

// Getters ====================

export const getters = {
  ...globalGetters
}
