import globalActions from "../api/GlobalActions.js"
import globalMutations from "../api/GlobalMutations.js"
import globalGetters from "../api/GlobalGetters.js"

export const state = () => ({
  isStale: true,
  latest_fetch: "",
  type: "producerHistory",
  canEdit: false,
  canView: false,
  effectedByYear: false,
  yearFields: [],
  aliasField: "id",
  modelLabels: {
    single: "Producer History",
    plural: "Producer History"
  },
  relations: [
    {
      name: "farmunit",
      translate: "farmunit",
      label: "Farm Unit",
      value: "farmunits",
      storename: "farmunits",
      yearFields: []
    }
  ],
  all: {},
  reference: {},
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: "updated_at",
    page: 1,
    sortDesc: ["updated_at"]
  },
  searchFields: ["producer"],
  print_path: "",
  total: 0,
  print: false,
  export: false,
  search: null,
  bound: {},
  stub: {},
  filter: {},
  quickFilters: [],
  include: "",
  headers: {},
  notifications: {}
})

export const mutations = {
  ...globalMutations
}

export const actions = {
  ...globalActions
}

export const getters = {
  ...globalGetters
}
