import globalActions from "../api/GlobalActions.js"
import globalMutations from "../api/GlobalMutations.js"
import globalGetters from "../api/GlobalGetters.js"

export const state = () => ({
  type: "logo",
  pageLayout: "StructureTable",
  canEdit: false,
  canView: false,
  effectedByYear: true,
  yearFields: [],
  aliasField: "id",
  relations: [
  ],
  all: {},
  reference: {
    /* for showing paginated resources ... needs a bit more work. */
  },
  modelLabels: {
    single: "Logo",
    plural: "Logos"
  },
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: "run_date",
    page: 1,
    sortDesc: []
  },
  total: 0,
  print: false,
  export: false,
  print_path: "",
  stub: {
    canal: {},
    farmunit: {},
    diversionPoint: {},
    user: {},
    producer: {}
  },

  bound: {

  },
  filter: {
  },
  include: "",
  headers: {},
  notifications: {}
})

// Mutations ==================

export const mutations = {
  ...globalMutations
}

// Actions ====================

export const actions = {
  ...globalActions,
  async upload_logo ({ commit, state, getters, dispatch, rootState }, payload) {
    const results = await this.$repositories[state.type].create(payload)

    if (results.hasOwnProperty("error")) {
      return false
    } else {
      await this.$auth.fetchUser()
    }
  }
}

// Getters ====================

export const getters = {
  ...globalGetters
}
