import globalActions from "../api/GlobalActions.js"
import globalMutations from "../api/GlobalMutations.js"
import globalGetters from "../api/GlobalGetters.js"

export const state = () => ({
  isStale: true,
  latest_fetch: "",
  type: "offlineRequests",
  effectedByYear: false,
  yearFields: [],
  aliasField: "id",
  relations: [],
  all: {},
  reference: {},
  active: false,
  page: 1,
  pageCount: 0,
  modelLabels: {
    single: "Offline Request",
    plural: "Offline Requests"
  },
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: "requested_at",
    page: 1,
    sortDesc: []
  },
  print_path: "",
  search: null,
  total: 0,
  print: false,
  export: false,
  bound: {},
  filter: {},
  quickFilters: [],
  include: "",
  headers: {},
  notifications: {}
})

export const mutations = {
  ...globalMutations
}

export const actions = {
  ...globalActions
}

export const getters = {
  ...globalGetters
}
