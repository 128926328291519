<template>
  <v-app>
    <main class=" align-center">
      <v-container fluid>
        <nuxt />
      </v-container>
    </main>
  </v-app>
</template>
<script>
export default {}
</script>

<style lang="scss" scoped>
</style>
