import Vue from "vue"
import { schema } from "normalizr"
import globalGetters from "../api/GlobalGetters.js"
import { resolveSchema } from "@/api/Helpers"

// memberHeadersSchema.define({})
export const state = () => ({
  type: "memberHeaders",
  schemaType: "memberHeadersSchema",
  canEdit: false,
  canView: false,
  effectedByYear: false,
  yearFields: [],
  aliasField: "section_name",
  relations: [],
  all: {},
  reference: {
    /* for showing paginated resources ... needs a bit more work. */
  },
  active: false,
  page: 1,
  filter: {},
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: "due_on",
    page: 1,
    sortDesc: []
  },
  total: 0,
  print: false,
  export: false,
  data: null,
  isLoading: false,
  indexes: [],
  types: [],
  columns: [],
  fieldMap: {},
  savedOrgColumns: [],
  activeFilters: [],
  bound: {},
  include: "",
  headers: [
    {
      align: "left",
      field: "label",
      text: "",
      icon: "mdi-label",
      protected: true,
      display: true,
      tableDisplay: false,
      filterDisplay: false,
      editable: true,
      label: "Label",
      type: "text",
      value: "label",
      used: "true",
      permission: "",
      flex: { xs: 12, sm: 12 },
      order: 1
    },
    {
      align: "left",
      field: "used",
      text: "",
      icon: "mdi-switch",
      protected: true,
      display: true,
      tableDisplay: false,
      filterDisplay: false,
      editable: true,
      label: "Use Field",
      type: "checkbox",
      value: "used",
      used: true,
      permission: "",
      flex: { xs: 12, sm: 12 },
      order: 1.1
    },
    {
      align: "left",
      field: "createDisplay",
      text: "",
      icon: "mdi-textbox",
      protected: true,
      display: true,
      tableDisplay: false,
      filterDisplay: false,
      editable: true,
      label: "View in Create Form",
      type: "checkbox",
      value: "createDisplay",
      used: "true",
      permission: "",
      flex: { xs: 12, sm: 12 },
      order: 1.2
    },
    {
      align: "left",
      field: "display",
      text: "",
      icon: "mdi-textbox",
      protected: true,
      display: true,
      tableDisplay: false,
      filterDisplay: false,
      editable: true,
      label: "View in Edit Form",
      type: "checkbox",
      value: "display",
      used: "true",
      permission: "",
      flex: { xs: 12, sm: 12 },
      order: 1.3
    },
    {
      align: "left",
      field: "filterDisplay",
      text: "",
      icon: "mdi-textbox",
      protected: true,
      display: true,
      tableDisplay: false,
      filterDisplay: false,
      editable: true,
      label: "Use in Filter",
      type: "checkbox",
      value: "filterDisplay",
      used: "true",
      permission: "",
      flex: { xs: 12, sm: 12 },
      order: 1.4
    },
    {
      align: "left",
      field: "tableDisplay",
      text: "",
      icon: "mdi-textbox",
      protected: true,
      display: true,
      tableDisplay: false,
      filterDisplay: false,
      editable: true,
      label: "Display in Table",
      type: "checkbox",
      value: "tableDisplay",
      used: "true",
      permission: "",
      flex: { xs: 12, sm: 12 },
      order: 1.5
    },
    {
      align: "left",
      field: "protected",
      text: "",
      icon: "mdi-shield-edit-outline",
      protected: true,
      display: true,
      tableDisplay: false,
      filterDisplay: false,
      editable: true,
      label: "Hidden from Public View",
      type: "checkbox",
      value: "label",
      used: "true",
      permission: "",
      flex: { xs: 12, sm: 12 },
      order: 2
    },
    {
      align: "left",
      field: "type",
      text: "",
      icon: "mdi-bookmark-outline",
      protected: true,
      display: true,
      tableDisplay: false,
      filterDisplay: false,
      editable: true,
      label: "type",
      type: "text",
      value: "type",
      used: "true",
      permission: "",
      flex: { xs: 12, sm: 12 },
      order: 3
    },
    {
      align: "left",
      field: "icon",
      text: "",
      icon: "mdi-textbox",
      protected: true,
      display: true,
      tableDisplay: false,
      filterDisplay: false,
      editable: true,
      label: "Select Icon",
      type: "select",
      value: "icon",
      freeModel: [{ name: "mdi-textbox" }, { name: "mdi-shield-edit-outline" }],
      itemText: "name",
      itemValue: "name",
      used: true,
      permission: "",
      flex: { xs: 12, sm: 12 },
      order: 6
    }
  ]
})

// Mutations ==================

export const mutations = {
  REPLACE_ALL (state, resource) {
    state.all = resource
  },
  MERGE (state, resource) {
    const index = state.all[resource.section_name]
    if (index === -1) {
      Vue.set(state.all, resource.section_name, resource)
    } else {
      Vue.set(state.all, resource.section_name, resource)
    }
  },
  setFieldMap: (state, payload) => {
    state.fieldMap = payload[0]
  },
  addHeaders (state, headers) {
    Object.assign(state.fieldMap, headers)
  },
  add (state, heading) {
    Vue.set(state.all, heading.field_name, heading)
  },
  addHeader (state, column) {
    if (state.columns.length === 0) {
      const d = {
        text: "Organization",
        align: "left",
        value: "company_name",
        field: "org",
        type: "read_only",
        protected: true
      }
      state.columns.push(d)
      state.savedOrgColumns.push(d)
    }
    const f = {
      text: column.title,
      field: column.field_name,
      type: column.type,
      value: column.field_name,
      align: "left",
      protected: false,
      sortable: true
    }
    if (column.type === "dropdown") {
      f.items = column.dropdown
    }

    state.columns.push(f)
    if (
      column.field_name === "anniversary" ||
      column.field_name === "status" ||
      column.field_name === "member_type"
    ) {
      if (!state.savedOrgColumns.some(c => c.field === column.field_name)) {
        state.savedOrgColumns.push(f)
      }
    }
  },
  addbinding (state, filter) {
    if (filter.type === "date") {
      Vue.set(state.bound, filter.field_name, { start: "", end: "" })
    } else {
      Vue.set(state.bound, filter.field_name, "")
    }
  },
  setSavedOrgColumns: (state, payload) => {
    state.savedOrgColumns = payload
  },
  update (state, { id, member }) {
    state.all[id] = member
  },
  updateBound (state, { key, value }) {
    state.bound[key] = value
  },
  resetBound (state, key) {
    state.bound[key] = ""
  },
  loading (state, data) {
    state.isLoading = data
  }
}

// Actions ====================
export const actions = {
  all ({ commit, state, dispatch }, payload) {
    payload.forEach((item) => {
      commit("MERGE", resolveSchema(item))
      const model = item.section_name

      dispatch(`${model}/UpdateBound`, item.schema, {
        root: true
      })
    })
  },
  async list ({ commit, state, getters, dispatch }) {
    if (!navigator.onLine) {
      return
    }
    const params = {}

    const results = await this.$repositories[state.type].index(params)
    if (results.hasOwnProperty("error")) {
      return
    }
    dispatch("all", results.data)
  },
  async update ({ commit, state, getters, dispatch }, { id, payload }) {
    const results = await this.$repositories[state.type].update({
      id,
      payload
    })
    if (results.hasOwnProperty("error")) {
      return
    }
    dispatch("all", results.data)
  }
}

// Getters ====================

export const getters = {
  ...globalGetters,

  companyHeaders: (state) => {
    const fields = Object.values(state.all.company_info.schema)

    const headers = {}
    fields.forEach(function (prop) {
      const f = {
        text: prop.name,
        field: prop.field_name,
        type: prop.type,
        value: prop.field_name,
        icon: prop.icon,
        align: "left",
        protected: false,
        sortable: true
      }
      if (prop.type === "dropdown") {
        f.items = prop.dropdown
      }
      headers[prop.field_name] = f
    })

    return Object.values(headers)
  },
  contactHeaders: (state) => {
    const fields = Object.values(state.all.contact_info.schema)

    const headers = {}
    fields.forEach(function (prop) {
      const f = {
        text: prop.name,
        field: prop.field_name,
        type: prop.type,
        value: prop.field_name,
        icon: prop.icon,
        align: "left",
        protected: false,
        sortable: true
      }
      if (prop.type === "dropdown") {
        f.items = prop.dropdown
      }
      headers[prop.field_name] = f
    })
    return Object.values(headers)
  },
  memberHeaders: (state) => {
    const fields = Object.values(state.all.member_info.schema)

    const headers = {}
    fields.forEach(function (prop) {
      const f = {
        text: prop.name,
        field: prop.field_name,
        type: prop.type,
        value: prop.field_name,
        icon: prop.icon,
        align: "left",
        protected: false,
        sortable: true
      }
      if (prop.type === "dropdown") {
        f.items = prop.dropdown
      }
      headers[prop.field_name] = f
    })
    return Object.values(headers)
  }

}
