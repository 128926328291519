import globalActions from "../api/GlobalActions.js"
import globalMutations from "../api/GlobalMutations.js"
import globalGetters from "../api/GlobalGetters.js"

export const state = () => ({
  type: "audits",
  effectedByYear: false,
  yearFields: [],
  aliasField: "id",
  modelLabels: {
    single: "Audit",
    plural: "Audits"
  },
  all: {},
  relations: [],
  reference: {},
  active: false,
  page: 1,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 50,
    sort: false,
    sortBy: "audit_date",
    page: 1,
    descending: true
  },
  total: 0,
  searchFields: ["user"],
  print: false,
  export: false,
  search: null,
  bound: {},
  filter: {},
  quickFilters: [],
  stub: {},
  include: "",
  headers: {},
  notifications: {}
})

// Mutations ==================

export const mutations = {
  ...globalMutations
}

// Actions ====================

export const actions = {
  ...globalActions
}

// Getters ====================

export const getters = {
  ...globalGetters
}
