<template>
  <v-overlay
    v-if="isLoading || load"
    :value="isLoading || load"
    :absolute="true"
  >
    <v-btn
      v-if="loadCount === 0"
      text
      :loading="isLoading || load"
      class="grey--text text--lighten-2"
    />
    <v-progress-circular
      v-else
      dark
      :rotate="360"
      :size="200"
      :width="15"
      :value="percentage"
      color="primary"
    >
      <h2>Loading...</h2>
    </v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    load: false
  }),
  computed: {
    loadCount () {
      return this.$store.state.system.loadCount
    },
    loadStatus () {
      return this.$store.state.system.loadStatus
    },
    percentage () {
      const multiplier = 100 / this.loadCount
      console.log(multiplier)
      return this.loadStatus * multiplier
    },
    isLoading: {
      get () {
        return this.loading
      },
      set (value) {
        return value
      }
    }
  },
  methods: {
    start () {
      this.isLoading = true
      this.load = true
    },
    finish () {
      this.isLoading = false
      this.load = false
    }
  }
}
</script>

<style lang="scss" scoped></style>
