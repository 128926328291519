<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    persistent
  >
    <!--
    <v-card
      v-if="displays==='Add'"
      class="mx-auto"
      max-width="600"
    >
      <v-card-title>
        Add Observation
      </v-card-title>
      <v-card-text>
        &lt;!&ndash;        <app-form :active="resource" :headers="headers" path="observations" />&ndash;&gt;
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          ref="close_add_observation_dialog"
          large
          color="warning"
          @click="closeSignal"
        >
          Cancel
        </v-btn>
        <v-btn
          ref="save_observation_btn"
          large
          color="primary"
          @click="addObservation"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>-->
    <v-card v-if="displays==='List'">
      <v-card-title v-if="!active" class="primary">
        <span class="headline grey--text text--lighten-2">Observations of the {{ dateUnit }}</span>
      </v-card-title>
      <v-card-title v-else class="primary">
        <span class="headline grey--text text--lighten-2">Update Observation</span>
      </v-card-title>
      <v-card-text v-if="!active" class="pt-2">
        <v-row>
          <v-col>
            <v-menu
              ref="observations_dp_menu"
              v-model="datePickerDialog"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  ref="observations_dp_menu_activator"
                  x-large
                  color="primary"
                  outlined
                  v-bind="attrs"
                  class="text-uppercase"
                  v-on="on"
                >
                  <v-icon left>
                    mdi-calendar
                  </v-icon>
                  {{ $moment(pickedDate).format(dateFormat) }}
                </v-btn>
              </template>
              <v-date-picker
                ref="observations_dp"
                v-model="pickedDate"
                :type="filterType"
                no-title
                scrollable
                @input="datePickerDialog = false"
              />
            </v-menu>
          </v-col>
          <v-col>
            <report-observations
              :filter-by-date="pickedDate"
              :opts="printOptions"
              class="float-right"
              icon="mdi-printer"
              text=""
            />
          </v-col>
        </v-row>
        <observations-list ref="observations_list" :filter-type="filterType" :filter-by-date="pickedDate" />
      </v-card-text>
      <v-card-text v-else class="pt-2">
        <v-btn class="mb-2" @click="flushActive">
          <v-icon>mdi-arrow-left</v-icon> Back to the list
        </v-btn>
        <v-alert type="warning" class="mb-2">
          Your changes will be saved immediately.
        </v-alert>
        <app-form ref="observations_edit_form" :active="active" :headers="createHeaders" path="observations" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          ref="close_observation_list_dialog"
          large
          color="warning"
          @click="closeSignal"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import ReportObservations from "@/components/common/reports/ReportObservations.vue"

export default {
  components: { ReportObservations },
  props: {
    displays: {
      type: String, // List, Add or None
      default: "None"
    }
  },
  data () {
    return {
      dialog: false,
      resource: {
        observed_at: this.$moment().format("YYYY-MM-DD"),
        observed_on: this.$moment().format("HH:ss")
      },
      datePickerDialog: false,
      pickedDate: this.$moment().format("YYYY-MM-DD"),
      filterType: "date", // for future improvement, the other option is "month"
      printOptions: {
        name: "Observations",
        description: "Generate report of observations",
        component: "ReportObservations",
        apiPath: "reportObservations",
        hints: {},
        availableToDitchRider: false
      }
    }
  },
  computed: {
    active () {
      return this.$store.getters["observations/active"]
    },
    createHeaders () {
      return this.$store.getters["observations/createHeaders"]
    },
    headers () {
      return this.$store.getters["observations/createHeaders"]
    },
    opts () {
      const opts = {}
      opts.headers = this.$store.getters[`${this.model}/createHeaders`]
      return opts
    },
    bound () {
      return this.$store.state[this.model].bound
    },
    dateUnit () {
      return this.filterType === "date" ? "day" : "month"
    },
    dateFormat () {
      return this.filterType === "date" ? "Do MMMM, YYYY" : "MMMM, YYYY"
    }
  },
  watch: {
    displays (newValue, oldValue) {
      this.dialog = this.displays !== "None"
    }
  },
  methods: {
    flushActive () {
      this.$store.commit("observations/FLUSH_ACTIVE")
    },
    closeSignal () {
      this.$emit("closeSignal")
    },
    closeCreate (param1, param2, param3) {
      console.log(param1, param2, param3)
    }
  }
}
</script>

<style scoped>

</style>
