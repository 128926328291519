import { stat } from "fs"
import { platform } from "os"
import _ from "lodash"
import Vue from "vue"

export const state = () => ({
  snack: "",
  snackSettings: {
    color: "secondary",
    top: false,
    timeout: 4000,
    right: true
  },
  activeView: false,
  loadStatus: 0,
  loadCount: 0,
  pastView: [],
  validateFields: {},
  showFormErrors: false,
  waterYear: null,
  availableWaterYears: [],
  customComponent: null,
  offlinePushRequests: [],
  cachedData: [],
  loadStores: [
    "appInfo",
    "memberheaders",
    "users",
    "flags",
    "cropTypes",
    "irrigationTypes",
    "districts",
    "canals",
    "dailycfs",
    "dailycfsFilters",
    "farmunits",
    "contacts",
    "diversionPoints",
    "orders",
    "maintenanceRequests",
    "announcements",
    "syncHistory",
    "omsettings",
    "observations"
  ]
})
export const actions = {
  async fetchStore ({ commit, state, dispatch }, { storeName, action, params }) {
    const store = dispatch(action, params, { root: true })
    if (store.meta && store.meta.current_page < store.meta.last_page) {
      // for (let p = parseInt(store.meta.current_page) + 1; p <= parseInt(store.meta.last_page); p++) {
      //   await commit(`${storeName}/SET_PAGE`, p, { root: true })
      await dispatch(action, params, { root: true })
      // }
      // return commit(`${storeName}/SET_PAGE`, 1, { root: true })
    }
  },
  async fetchSync ({ commit, state, dispatch, rootState }) {
    // await dispatch("fetchStore", { storeName: "cropTypes", action: "cropTypes/list", params: { actionType: "sync" } })
    // await dispatch("fetchStore", { storeName: "irrigationTypes", action: "irrigationTypes/list", params: { actionType: "sync" } })

    await dispatch("fetchStore", {
      storeName: "contacts",
      action: "contacts/owners",
      params: { routePath: "owners", actionType: "sync" }
    })
    await dispatch("fetchStore", {
      storeName: "contacts",
      action: "contacts/producers",
      params: { keepCache: true, routePath: "producers", actionType: "sync" }
    })

    //  await dispatch("fetchStore", { storeName: "canals", action: "canals/list", params: { actionType: "sync" } })
    // await dispatch("fetchStore", { storeName: "districts", action: "districts/list", params: { actionType: "sync" } })
    // await dispatch("fetchStore", { storeName: "diversionPoints", action: "diversionPoints/list", params: { actionType: "sync" } })
    // await dispatch("fetchStore", { storeName: "farmunits", action: "farmunits/list", params: { actionType: "sync" } })
    // await dispatch("farmunits/list", { actionType: "sync" }, { root: true })

    await dispatch("fetchStore", {
      storeName: "orders",
      action: "orders/running",
      params: { routePath: "runningOrders", actionType: "sync" }
    })
    await dispatch("fetchStore", {
      storeName: "orders",
      action: "orders/draft",
      params: { keepCache: true, routePath: "draftOrders", actionType: "sync" }
    })
    await dispatch("fetchStore", {
      storeName: "orders",
      action: "orders/revoked",
      params: {
        keepCache: true,
        routePath: "revokedOrders",
        actionType: "sync"
      }
    })
    await dispatch("fetchStore", {
      storeName: "orders",
      action: "orders/closed",
      params: { keepCache: true, routePath: "closedOrders", actionType: "sync" }
    })
    // await dispatch("fetchStore", { storeName: "users", action: "users/list", params: { actionType: "sync" } })

    await this.$repositories.synchronizations.index()
    return dispatch("syncStats/show", 1, { root: true })
  },

  async pushSync ({ commit, state, dispatch }) {
    const offlinePushRequests = state.offlinePushRequests
    if (offlinePushRequests.length > 0) {
      const pushSync = await this.$repositories.synchronizations.create({})
      if (pushSync.hasOwnProperty("error")) {
        return
      }

      for (const req of offlinePushRequests) {
        await dispatch("sendRequest", { req, pushSync })
      }
      await dispatch("syncStats/show", 1, { root: true })
    }
  },

  async sendRequest ({ commit, state, dispatch }, { req, pushSync }) {
    let result = {}
    if (req.type === "subCreate") {
      result = await this.$repositories[req.payload.type].subCreate(
        req.payload.id,
        req.payload.resource
      )
    } else if (req.type === "create") {
      result = await this.$repositories[req.state].create(req.payload)
    } else if (req.type === "update") {
      result = await this.$repositories[req.state].update({
        id: req.payload.id,
        payload: req.payload.payload
      })
    }

    const logObject = { ...req }

    if (pushSync) {
      logObject.synchronization_id = pushSync.data.id
    }

    if (result.hasOwnProperty("error")) {
      logObject.status = "error"
      logObject.errorData = result.error
    } else {
      if (["create", "subCreate"].includes(req.type)) {
        logObject.created_model_id = result.data.id
      }
      await commit("REMOVE_OFFLINE_REQUEST", req.requestId)
      await commit(req.state + "/REMOVE", req.requestId, { root: true })
      logObject.status = "success"
    }
    await this.$repositories.offlineRequests.create(logObject)
  },

  // eslint-disable-next-line require-await
  async resetPassword ({ commit, state }, payload) {
    return this.$repositories.forgotPassword.create(payload)
  },

  active_view ({ commit, state }, payload) {
    if (payload.hasOwnProperty("customComponent")) {
      commit("SET_CUSTOM_COMPONENT", payload.customComponent)
    } else {
      commit("SET_CUSTOM_COMPONENT", null)
    }

    commit("SET_ACTIVE_VIEW", payload.view)
    commit("SET_HISTORY", payload)
  },
  async navBack ({ commit, state, getters, dispatch, rootState }) {
    const newActive = state.pastView[1]
    await commit("NAVIGATE_ACTIVE_DIALOG_BACK")
    return newActive
  },
  setWaterYear ({ commit, dispatch, rootState }, value) {
    // Set system year value
    commit("SET_WATER_YEAR", value)
    const om = Object.values(rootState.omsettings.all).find(
      om => parseInt(om.year) === parseInt(value)
    )

    // Object to use in filters based on selected year
    const yearObject = {
      start: om ? om.water_year_start : `${value}-01-01`,
      end: om ? om.water_year_end : `${value}-12-31`
    }

    // Loop through all stores available in vuex and set filters accordingly
    for (const [k, v] of Object.entries(rootState)) {
      const existingFilter = v.filter
      const newFilter = {}

      // Find fields of the store object effected by year and add that to filter
      if (v.hasOwnProperty("effectedByYear") && v.effectedByYear) {
        if (v.hasOwnProperty("yearFields") && v.yearFields.length > 0) {
          const attrBetweenDate = {}
          for (const item of v.yearFields) {
            attrBetweenDate[item] = yearObject
          }
          newFilter.attr_between_date = attrBetweenDate
        }

        // Find relations of the store object and add their fields effected by year to filter
        if (v.hasOwnProperty("relations") && v.relations.length > 0) {
          const obj = {}

          for (const item of Object.values(v.relations)) {
            if (
              item.hasOwnProperty("yearFields") &&
              item.yearFields.length > 0
            ) {
              const items = {
                attr_between_date: {}
              }
              for (const el of item.yearFields) {
                items.attr_between_date[el] = yearObject
              }
              obj[item.translate] = items
            }
          }

          if (Object.values(obj).length > 0) {
            newFilter.relations = obj
          }
        }

        commit(
          `${k}/replaceFilter`,
          { ...existingFilter, ...newFilter },
          { root: true }
        )
      }
    }
  }
}
export const mutations = {
  NAVIGATE_ACTIVE_DIALOG_BACK (state) {
    const pastView = state.pastView
    pastView.splice(0, 2)
    Vue.set(state, "pastView", [...pastView])
  },

  SET_CUSTOM_COMPONENT (state, componentName) {
    state.customComponent = componentName
  },

  SET_DYNAMIC (state, resource) {
    state[resource.key] = resource.value
  },

  SET_ACTIVE_VIEW (state, active) {
    state.activeView = active
  },

  SET_LOADCOUNT (state, payload) {
    state.loadCount = payload
  },

  SET_LOADSTATUS (state, payload) {
    state.loadStatus = payload
  },

  SET_HISTORY (state, payload) {
    state.pastView.unshift(payload)
  },

  FLUSH_HISTORY (state) {
    state.pastView = []
  },

  SET_SNACK (state, snack) {
    state.snack = snack
  },

  set_snackSettings: (state, snackSettings) => {
    state.snackSettings = snackSettings
  },

  set_validateFields: (state, validateFields) => {
    state.validateFields = validateFields
  },

  set_showFormErrors: (state, showFormErrors) => {
    state.showFormErrors = showFormErrors
  },

  SET_ALL_WATER_YEARS (state, payload) {
    state.availableWaterYears = Object.values(payload)
  },

  SET_WATER_YEAR (state, payload) {
    state.waterYear = payload
  },

  PUT_OFFLINE_REQUEST (state, payload) {
    const offlinePushRequests = state.offlinePushRequests
    offlinePushRequests.push(payload)
    state.offlinePushRequests = offlinePushRequests
  },

  UPDATE_OFFLINE_REQUEST (state, payload) {
    const offlinePushRequests = state.offlinePushRequests
    const item = offlinePushRequests.findIndex(
      i => i.requestId === payload.requestId
    )
    offlinePushRequests.splice(item, 1, payload)
    state.offlinePushRequests = offlinePushRequests
  },

  REMOVE_OFFLINE_REQUEST (state, requestId) {
    state.offlinePushRequests = state.offlinePushRequests.filter(
      or => or.requestId !== requestId
    )
  }
}
export const getters = {
  permissions (state, commit, rootState) {
    let permissions = []
    permissions = rootState.auth.user.data.can
    return permissions
  },
  roles (state, commit, rootState) {
    let roles = []
    if (rootState.auth.user.data) {
      roles = rootState.auth.user.data.roles
    }
    return roles
  },
  getWaterYear (state) {
    return state.waterYear
  },
  getAllWaterYears (state) {
    return state.availableWaterYears
  },
  getLatestWaterYear (state) {
    const newOrder = JSON.parse(JSON.stringify(state.availableWaterYears))
    newOrder.sort()
    newOrder.reverse()
    return newOrder[0]
  },
  getPastView (state) {
    return _.cloneDeep(state.pastView)
  }
}
