import globalActions from "../api/GlobalActions.js"
import globalMutations from "../api/GlobalMutations.js"
import globalGetters from "../api/GlobalGetters.js"

export const state = () => ({
  isStale: true,
  latest_fetch: "",
  type: "observations",
  canEdit: false,
  canView: false,
  effectedByYear: false,
  yearFields: [],
  modelLabels: {
    single: "Observation",
    plural: "Observations"
  },
  filterOverrides: [],
  aliasField: "id",
  relations: [
    {
      name: "user",
      translate: "user",
      label: "User",
      value: "users",
      storename: "users",
      yearFields: []
    },
    {
      name: "updated_by",
      translate: "updated_by",
      label: "Updated By",
      value: "updated_by",
      storename: "users",
      yearFields: []
    }
  ],
  all: {},
  reference: {
    /* for showing paginated resources ... needs a bit more work. */
  },
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 100,
    sort: false,
    sortBy: "name",
    page: 1,
    sortDesc: []
  },
  total: 0,
  print_path: "",
  print: false,
  export: false,
  search: null,
  searchFields: ["location", "note"],
  bound: {},
  filter: {},
  quickFilters: [],
  stub: {
    user: {},
    updated_by: {}
  },
  // members are called contractors on the server
  include: "user,updated_by",
  headers: {},
  notifications: {}
})

// Mutations ==================

export const mutations = {
  ...globalMutations
}

// Actions ====================

export const actions = {
  ...globalActions
}

// Getters ====================

export const getters = {
  ...globalGetters
}
