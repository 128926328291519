export function resolveRelations (data, fields, rootState) {
  const results = {}
  fields.forEach((field) => {
    const valuefield = field.storename ? field.storename : field.value

    results[field.name] = []
    if (Array.isArray(data[field.name])) {
      results[field.name] = data[field.name].map(
        x => rootState[valuefield].all[x]
      )
    } else {
      results[field.name] = rootState[valuefield].all[data[field.name]]
    }
  })
  return { ...data, ...results }
}

export function resolveSchema (data) {
  const results = {}
  // let schema = JSON.parse(data.schema);
  results.id = data.id
  results.section_name = data.section_name
  results.schema = data.schema
  return results
}

export function resolveNotifications (data) {
  const results = {}
  // let schema = JSON.parse(data.schema);
  results.id = data.id
  results.type = data.type
  results.schema = data.data
  return results
}

export function titleCase (str) {
  return str.replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
    .replace(/[-_]+(.)/g, (_, c) => " " + c.toUpperCase()) // First char after each -/_
}

export function compareVersions (firstVersionStr, secondVersionStr) {
  // return if the first >= second

  if (firstVersionStr === secondVersionStr) {
    return true
  }

  const firstSplit = firstVersionStr.split(".").map(item => Number(item))
  const secSplit = secondVersionStr.split(".").map(item => Number(item))
  const fractions = firstSplit.length > secSplit.length
    ? firstSplit.length
    : secSplit.length

  for (let i = 0; i < fractions; i++) {
    console.log("in loop")
    const itemOfFirst = firstSplit.length > i ? firstSplit[i] : 0
    const itemOfSec = secSplit.length > i ? secSplit[i] : 0
    // console.log({ firstSplit, secSplit, fractions, i, itemOfFirst, itemOfSec })
    if (itemOfFirst > itemOfSec) {
      return true
    } else if (itemOfFirst < itemOfSec) {
      return false
    }
  }
  // equality here.
  return true
}
