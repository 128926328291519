<template>
  <div>
    <v-card flat>
      <v-btn
        :text="text && !icon"
        :rounded="false"
        outlined
        class="primary--text"
        @click.stop="dialog = true"
      >
        <v-icon v-if="icon" :left="text!==''">
          {{ icon }}
        </v-icon>
        {{ text }}
      </v-btn>
    </v-card>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <app-overlay :loading="loading" />
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Get Report</v-toolbar-title>
          <v-spacer />
        </v-toolbar>

        <v-card-text v-if="!printPath">
          <app-form
            :active="active"
            :headers="computedHeaders"
            :meta="true"
            @act="success"
          />
          <ul v-if="errorMessages.length" class="message error">
            <li v-for="(message, index) in errorMessages" :key="index">
              {{ message }}
            </li>
          </ul>
        </v-card-text>
        <v-card-actions v-if="!printPath">
          <v-btn color="primary" @click="get()">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    opts: {
      type: Object,
      required: true
    },
    icon: {
      type: String,
      required: false,
      default: ""
    },
    text: {
      type: String,
      required: false,
      default: "Generate"
    },
    dateFilterUnit: {
      type: String,
      default: "date" // date or month
    },
    filterByDate: {
      type: String,
      required: true
    },
    filterByUser: {
      type: Array || Number,
      default () {
        return []
      }
    }
  },
  data: () => ({
    errorMessages: [],
    dialog: false,
    date: "",
    printPath: null,
    printDialog: false,
    loading: false,
    active: {
      title: ""
    },
    headers: [
      {
        align: "left",
        field: "title",
        icon: "mdi-format-title",
        protected: true,
        display: true,
        tableDisplay: false,
        filterDisplay: true,
        createDisplay: true,
        editable: false,
        label: "Report Title",
        type: "text",
        tableType: "text",
        required: false,
        custom_1: "",
        custom_2: "",
        custom_3: "",
        value: "title",
        used: true,
        permission: "",
        flex: {
          xs: 12,
          sm: 12
        },
        order: 1,
        column: 1
      }/*,
      {
        align: "left",
        field: "district",
        icon: "mdi-office-building",
        protected: true,
        display: true,
        tableDisplay: false,
        filterDisplay: true,
        createDisplay: true,
        editable: false,
        label: "District",
        type: "modelAutocomplete",
        tableType: "list",
        required: false,
        custom_1: "",
        custom_2: "",
        custom_3: "",
        value: "district",
        used: true,
        permission: "",
        model: "districts",
        itemValue: "id",
        itemText: "name",
        multiple: true,
        mappedField: "district.id",
        flex: {
          xs: 12,
          sm: 12
        },
        order: 2,
        column: 2
      } */
    ]
  }),
  computed: {
    computedHeaders () {
      return this.headers.map((h) => {
        if (this.opts.hints && this.opts.hints.hasOwnProperty(h.field)) {
          h.hint = this.opts.hints[h.field]
        }
        return h
      })
    }
  },
  methods: {
    async get () {
      this.loading = true

      const x = {}

      x.date = this.filterByDate
      x.date_filter_unit = this.dateFilterUnit
      x.title = this.active.title
      x.print = true
      x.waterYear = this.$store.state.system.waterYear

      const results = await this.$repositories[this.opts.apiPath].index(x)

      if (results.error) {
        this.errorMessages = _.flattenDeep(Object.values(results.error.data.errors))
      } else {
        this.printPath = results.data.path
        window.open(`${this.printPath}`)
      }

      this.loading = false
      this.close()
    },
    success (e, field) {
      this.errorMessages = []
      this.active[field] = e
    },
    close () {
      this.dialog = false
      this.printPath = null
      this.errorMessages = []
      // this.$emit("close")
    }
  }
}
</script>

<style scoped>
.message {
  list-style: none;
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
