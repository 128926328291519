import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _174e2654 = () => interopDefault(import('../pages/announcements/index.vue' /* webpackChunkName: "pages/announcements/index" */))
const _32fc770e = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _eb6ca1b8 = () => interopDefault(import('../pages/canals/index.vue' /* webpackChunkName: "pages/canals/index" */))
const _040ea519 = () => interopDefault(import('../pages/CanalSchedule.vue' /* webpackChunkName: "pages/CanalSchedule" */))
const _244e019f = () => interopDefault(import('../pages/contacts/index.vue' /* webpackChunkName: "pages/contacts/index" */))
const _3ce33fd3 = () => interopDefault(import('../pages/cropTypes/index.vue' /* webpackChunkName: "pages/cropTypes/index" */))
const _22688d80 = () => interopDefault(import('../pages/dailycfs.vue' /* webpackChunkName: "pages/dailycfs" */))
const _aa1258a2 = () => interopDefault(import('../pages/districts/index.vue' /* webpackChunkName: "pages/districts/index" */))
const _2d0cfc00 = () => interopDefault(import('../pages/diversionPoints/index.vue' /* webpackChunkName: "pages/diversionPoints/index" */))
const _4fa45b08 = () => interopDefault(import('../pages/dock.vue' /* webpackChunkName: "pages/dock" */))
const _39711bf8 = () => interopDefault(import('../pages/documentation/index.vue' /* webpackChunkName: "pages/documentation/index" */))
const _97e225fa = () => interopDefault(import('../pages/farmunits/index.vue' /* webpackChunkName: "pages/farmunits/index" */))
const _d14bfede = () => interopDefault(import('../pages/flags/index.vue' /* webpackChunkName: "pages/flags/index" */))
const _79302156 = () => interopDefault(import('../pages/irrigationTypes/index.vue' /* webpackChunkName: "pages/irrigationTypes/index" */))
const _b9774720 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _d1c5487e = () => interopDefault(import('../pages/maintenanceRequests/index.vue' /* webpackChunkName: "pages/maintenanceRequests/index" */))
const _281cb38d = () => interopDefault(import('../pages/omsettings/index.vue' /* webpackChunkName: "pages/omsettings/index" */))
const _2a3e3df1 = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _44abdb1e = () => interopDefault(import('../pages/ResetPassword.vue' /* webpackChunkName: "pages/ResetPassword" */))
const _7abed96f = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _d32a8762 = () => interopDefault(import('../pages/specialReports.vue' /* webpackChunkName: "pages/specialReports" */))
const _16f3f2d2 = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _95520468 = () => interopDefault(import('../pages/documentation/api.vue' /* webpackChunkName: "pages/documentation/api" */))
const _beae0962 = () => interopDefault(import('../pages/documentation/api/index.vue' /* webpackChunkName: "pages/documentation/api/index" */))
const _50a9b1ab = () => interopDefault(import('../pages/documentation/farmunits.vue' /* webpackChunkName: "pages/documentation/farmunits" */))
const _453dcaed = () => interopDefault(import('../pages/orders/create.vue' /* webpackChunkName: "pages/orders/create" */))
const _2522379a = () => interopDefault(import('../pages/settings/customizations.vue' /* webpackChunkName: "pages/settings/customizations" */))
const _12f17f1e = () => interopDefault(import('../pages/settings/logo.vue' /* webpackChunkName: "pages/settings/logo" */))
const _407ca627 = () => interopDefault(import('../pages/settings/offlineRequests/index.vue' /* webpackChunkName: "pages/settings/offlineRequests/index" */))
const _14565422 = () => interopDefault(import('../pages/settings/uploads.vue' /* webpackChunkName: "pages/settings/uploads" */))
const _15c82cbe = () => interopDefault(import('../pages/announcements/_id.vue' /* webpackChunkName: "pages/announcements/_id" */))
const _53fdaae8 = () => interopDefault(import('../pages/canals/_id.vue' /* webpackChunkName: "pages/canals/_id" */))
const _d7444072 = () => interopDefault(import('../pages/contacts/_id.vue' /* webpackChunkName: "pages/contacts/_id" */))
const _2dd51afb = () => interopDefault(import('../pages/cropTypes/_id.vue' /* webpackChunkName: "pages/cropTypes/_id" */))
const _8f981452 = () => interopDefault(import('../pages/districts/_id.vue' /* webpackChunkName: "pages/districts/_id" */))
const _1d0816eb = () => interopDefault(import('../pages/diversionPoints/_id/index.vue' /* webpackChunkName: "pages/diversionPoints/_id/index" */))
const _356a542b = () => interopDefault(import('../pages/farmunits/_id.vue' /* webpackChunkName: "pages/farmunits/_id" */))
const _483e198e = () => interopDefault(import('../pages/flags/_id.vue' /* webpackChunkName: "pages/flags/_id" */))
const _5d63c2fd = () => interopDefault(import('../pages/irrigationTypes/_id.vue' /* webpackChunkName: "pages/irrigationTypes/_id" */))
const _6a215a69 = () => interopDefault(import('../pages/maintenanceRequests/_id.vue' /* webpackChunkName: "pages/maintenanceRequests/_id" */))
const _3b279596 = () => interopDefault(import('../pages/omsettings/_id.vue' /* webpackChunkName: "pages/omsettings/_id" */))
const _03750899 = () => interopDefault(import('../pages/orders/_id.vue' /* webpackChunkName: "pages/orders/_id" */))
const _43b6fc8c = () => interopDefault(import('../pages/users/_id.vue' /* webpackChunkName: "pages/users/_id" */))
const _ef9892c8 = () => interopDefault(import('../pages/diversionPoints/_id/orders.vue' /* webpackChunkName: "pages/diversionPoints/_id/orders" */))
const _4cc68d4e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/announcements",
    component: _174e2654,
    name: "announcements"
  }, {
    path: "/callback",
    component: _32fc770e,
    name: "callback"
  }, {
    path: "/canals",
    component: _eb6ca1b8,
    name: "canals"
  }, {
    path: "/CanalSchedule",
    component: _040ea519,
    name: "CanalSchedule"
  }, {
    path: "/contacts",
    component: _244e019f,
    name: "contacts"
  }, {
    path: "/cropTypes",
    component: _3ce33fd3,
    name: "cropTypes"
  }, {
    path: "/dailycfs",
    component: _22688d80,
    name: "dailycfs"
  }, {
    path: "/districts",
    component: _aa1258a2,
    name: "districts"
  }, {
    path: "/diversionPoints",
    component: _2d0cfc00,
    name: "diversionPoints"
  }, {
    path: "/dock",
    component: _4fa45b08,
    name: "dock"
  }, {
    path: "/documentation",
    component: _39711bf8,
    name: "documentation"
  }, {
    path: "/farmunits",
    component: _97e225fa,
    name: "farmunits"
  }, {
    path: "/flags",
    component: _d14bfede,
    name: "flags"
  }, {
    path: "/irrigationTypes",
    component: _79302156,
    name: "irrigationTypes"
  }, {
    path: "/login",
    component: _b9774720,
    name: "login"
  }, {
    path: "/maintenanceRequests",
    component: _d1c5487e,
    name: "maintenanceRequests"
  }, {
    path: "/omsettings",
    component: _281cb38d,
    name: "omsettings"
  }, {
    path: "/orders",
    component: _2a3e3df1,
    name: "orders"
  }, {
    path: "/ResetPassword",
    component: _44abdb1e,
    name: "ResetPassword"
  }, {
    path: "/settings",
    component: _7abed96f,
    name: "settings"
  }, {
    path: "/specialReports",
    component: _d32a8762,
    name: "specialReports"
  }, {
    path: "/users",
    component: _16f3f2d2,
    name: "users"
  }, {
    path: "/documentation/api",
    component: _95520468,
    children: [{
      path: "",
      component: _beae0962,
      name: "documentation-api"
    }]
  }, {
    path: "/documentation/farmunits",
    component: _50a9b1ab,
    name: "documentation-farmunits"
  }, {
    path: "/orders/create",
    component: _453dcaed,
    name: "orders-create"
  }, {
    path: "/settings/customizations",
    component: _2522379a,
    name: "settings-customizations"
  }, {
    path: "/settings/logo",
    component: _12f17f1e,
    name: "settings-logo"
  }, {
    path: "/settings/offlineRequests",
    component: _407ca627,
    name: "settings-offlineRequests"
  }, {
    path: "/settings/uploads",
    component: _14565422,
    name: "settings-uploads"
  }, {
    path: "/announcements/:id",
    component: _15c82cbe,
    name: "announcements-id"
  }, {
    path: "/canals/:id",
    component: _53fdaae8,
    name: "canals-id"
  }, {
    path: "/contacts/:id",
    component: _d7444072,
    name: "contacts-id"
  }, {
    path: "/cropTypes/:id",
    component: _2dd51afb,
    name: "cropTypes-id"
  }, {
    path: "/districts/:id",
    component: _8f981452,
    name: "districts-id"
  }, {
    path: "/diversionPoints/:id",
    component: _1d0816eb,
    name: "diversionPoints-id"
  }, {
    path: "/farmunits/:id",
    component: _356a542b,
    name: "farmunits-id"
  }, {
    path: "/flags/:id",
    component: _483e198e,
    name: "flags-id"
  }, {
    path: "/irrigationTypes/:id",
    component: _5d63c2fd,
    name: "irrigationTypes-id"
  }, {
    path: "/maintenanceRequests/:id",
    component: _6a215a69,
    name: "maintenanceRequests-id"
  }, {
    path: "/omsettings/:id",
    component: _3b279596,
    name: "omsettings-id"
  }, {
    path: "/orders/:id",
    component: _03750899,
    name: "orders-id"
  }, {
    path: "/users/:id",
    component: _43b6fc8c,
    name: "users-id"
  }, {
    path: "/diversionPoints/:id/orders",
    component: _ef9892c8,
    name: "diversionPoints-id-orders"
  }, {
    path: "/",
    component: _4cc68d4e,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
