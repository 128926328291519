import moment from "moment"
import config from "~/config/filterMenu.js"

export function filterRelationLabels () {
  return [
    {
      field: "contacts",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Attach Contact",
      type: "modelAutocomplete",
      value: "contacts",
      used: true,
      model: "contacts",
      itemValue: "id",
      itemText: "full_name",
      multiple: true,
      mappedField: "contact.id",
      mapped: "contacts",
      order: 1,
      column: 1
    },
    {
      field: "owns",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Attach Farmunit",
      type: "modelAutocomplete",
      value: "farmowner",
      used: true,
      model: "farmunits",
      itemValue: "id",
      itemText: "full_name",
      multiple: true,
      mappedField: "farmunit.id",
      mapped: "farmowner",
      order: 1,
      column: 1
    },
    {
      field: "produces",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Attach Farmunit",
      type: "modelAutocomplete",
      value: "farmproducer",
      used: true,
      model: "farmunits",
      itemValue: "id",
      itemText: "full_name",
      multiple: true,
      mappedField: "farmunit.id",
      mapped: "farmproducer",
      order: 1,
      column: 1
    },
    {
      field: "flags",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Attach Flags",
      type: "modelAutocomplete",
      value: "flags",
      used: true,
      model: "flags",
      itemValue: "id",
      itemText: "full_name",
      multiple: true,
      mappedField: "flag.id",
      mapped: "flags",
      order: 1,
      column: 1
    },
    {
      field: "diversionPoints",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Attach diversionPoints",
      type: "modelAutocomplete",
      value: "diversionPoints",
      used: true,
      model: "diversionPoints",
      itemValue: "id",
      itemText: "full_name",
      multiple: true,
      mappedField: "diversionPoint.id",
      mapped: "diversionPoints",
      order: 1,
      column: 1
    },
    {
      field: "diversionPoint",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Attach diversionPoint",
      type: "modelAutocomplete",
      value: "diversionPoint",
      used: true,
      model: "diversionPoints",
      itemValue: "id",
      itemText: "full_name",
      multiple: true,
      mappedField: "diversionPoint.id",
      mapped: "diversionPoint",
      order: 1,
      column: 1
    },
    {
      field: "farmunits",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Attach Farmunits",
      type: "modelAutocomplete",
      value: "farmunits",
      used: true,
      model: "farmunits",
      itemValue: "id",
      itemText: "full_name",
      multiple: true,
      mappedField: "farmunit.id",
      mapped: "farmunits",
      order: 1,
      column: 1
    },
    {
      field: "farmunit",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Attach Farmunits",
      type: "modelAutocomplete",
      value: "farmunit",
      used: true,
      model: "farmunits",
      itemValue: "id",
      itemText: "full_name",
      multiple: true,
      mappedField: "farmunit.id",
      mapped: "farmunit",
      order: 1,
      column: 1
    },
    {
      field: "canals",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Attach Canal",
      type: "modelAutocomplete",
      value: "canals",
      used: true,
      model: "canals",
      itemValue: "id",
      itemText: "full_name",
      multiple: true,
      mappedField: "canal.id",
      mapped: "canals",
      order: 1,
      column: 1
    },
    {
      field: "canal",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Attach Canal",
      type: "modelAutocomplete",
      value: "canal",
      used: true,
      model: "canals",
      itemValue: "id",
      itemText: "full_name",
      multiple: true,
      mappedField: "canal.id",
      mapped: "canal",
      order: 1,
      column: 1
    },
    {
      field: "district",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Attach Canal",
      type: "modelAutocomplete",
      value: "district",
      used: true,
      model: "districts",
      itemValue: "id",
      itemText: "full_name",
      multiple: true,
      mappedField: "district.id",
      mapped: "district",
      order: 1,
      column: 1
    },
    {
      field: "districts",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Attach Canal",
      type: "modelAutocomplete",
      value: "districts",
      used: true,
      model: "districts",
      itemValue: "id",
      itemText: "full_name",
      multiple: true,
      mappedField: "district.id",
      mapped: "districts",
      order: 1,
      column: 1
    },
    {
      field: "orders",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Attach Canal",
      type: "modelAutocomplete",
      value: "orders",
      used: true,
      model: "orders",
      itemValue: "id",
      itemText: "full_name",
      multiple: true,
      mappedField: "order.id",
      mapped: "orders",
      order: 1,
      column: 1
    },
    {
      field: "usages",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Attach Canal",
      type: "modelAutocomplete",
      value: "usages",
      used: true,
      model: "usages",
      itemValue: "id",
      itemText: "full_name",
      multiple: true,
      mappedField: "usage.id",
      mapped: "usages",
      order: 1,
      column: 1
    },
    {
      field: "diversionPoints",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Attach Diversion Point",
      type: "modelAutocomplete",
      value: "diversionPoints",
      used: true,
      model: "diversionPoints",
      itemValue: "id",
      itemText: "full_name",
      multiple: true,
      mappedField: "diversionPoint.id",
      mapped: "diversionPoints",
      order: 1,
      column: 1
    },
    {
      field: "owners",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Attach Owners",
      type: "modelAutocomplete",
      value: "owners",
      used: true,
      model: "contacts",
      itemValue: "id",
      itemText: "full_name",
      multiple: true,
      mappedField: "owner.id",
      mapped: "owners",
      order: 1,
      column: 1
    },
    {
      field: "producer",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Attach Producer",
      type: "modelAutocomplete",
      value: "producer",
      used: true,
      model: "contacts",
      itemValue: "id",
      itemText: "full_name",
      multiple: true,
      mappedField: "producer.id",
      mapped: "producer",
      order: 1,
      column: 1
    },
    {
      field: "secondary",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Attach Producer",
      type: "modelAutocomplete",
      value: "secondary",
      used: true,
      model: "contacts",
      itemValue: "id",
      itemText: "full_name",
      multiple: true,
      mappedField: "secondary.id",
      mapped: "secondary",
      order: 1,
      column: 1
    },
    {
      field: "voter",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Attach Voter",
      type: "modelAutocomplete",
      value: "voter",
      used: true,
      model: "contacts",
      itemValue: "id",
      itemText: "full_name",
      multiple: true,
      mappedField: "voter.id",
      mapped: "voter",
      order: 1,
      column: 1
    },
    {
      field: "user",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Attach User",
      type: "modelAutocomplete",
      value: "user",
      used: true,
      model: "users",
      itemValue: "id",
      itemText: "full_name",
      multiple: true,
      mappedField: "user.id",
      mapped: "user",
      order: 1,
      column: 1
    },
    {
      field: "users",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Attach User",
      type: "modelAutocomplete",
      value: "users",
      used: true,
      model: "users",
      itemValue: "id",
      itemText: "full_name",
      multiple: true,
      mappedField: "user.id",
      mapped: "users",
      order: 1,
      column: 1
    }
  ]
}

export function resolveFilters (item, value) {
  let result = ""
  let display = ""
  let today = ""
  switch (item) {
    case "without_attr":
      result = "missing" + value.join(",")
      break
    case "with_attr":
      result = "Any" + value.join(",")
      break
    case "without_rel":
      result = "missing" + value.join(",")
      break
    case "with_rel":
      display = value.filter(obj => obj.text)
      result = display.join(",")
      break
    case "attr_before_date":
      result = "Before " + moment(value[0], "YYYY-MM-DD").format("MM/DD/YY")
      break
    case "attr_between_date":
      result =
        moment(value[0], "YYYY-MM-DD").format("MM/DD/YY") +
        " - " +
        moment(value[1], "YYYY-MM-DD").format("MM/DD/YY")
      console.log(value[0])
      break
    case "attr_between_days_next":
      result =
        moment()
          .add(value[0], "days")
          .format("MM/DD/YY") +
        " - " +
        moment()
          .add(value[1], "days")
          .format("MM/DD/YY")
      break
    case "attr_between_days_past":
      result =
        moment()
          .subtract(value[0], "days")
          .format("MM/DD/YY") +
        " - " +
        moment()
          .subtract(value[1], "days")
          .format("MM/DD/YY")
      break
    case "attr_within_days_next":
      console.log("value[0]")
      console.log(value[0])
      result =
        moment().format("MM/DD/YY") +
        " - " +
        moment()
          .add(value[0], "days")
          .format("MM/DD/YY")
      break
    case "attr_this_month":
      today = moment().format("MMM, YYYY")
      result = "in " + today
      break

    default:
      result = value
  }

  return result
}

export function formatFilter (filter) {
  const list = []
  _.forEach(filter, function (item, type) {
    if (type === "exact") {
      Object.entries(item).forEach(([key, value]) => {
        const entryValue = []
        Object.entries(value).forEach(([k, v]) => {
          entryValue.push(v)
        })
        const obj = {
          value: entryValue.join(","),
          type,
          key
        }
        list.push(obj)
      })
    } else {
      Object.entries(filter).forEach(([key, value]) => {
        const obj = {
          value: resolveFilterFormat(type, Object.values(value)),
          type,
          key
        }
        list.push(obj)
      })
    }
  })

  return list
}

// import this into vuex getters
export function resolveRelationFilters (relations) {
  const list = []

  _.forEach(relations, function (filter, name) {
    // filter is an obj {with_rel: {id:6}}
    // name = author

    const temp = formatFilter(filter)

    // probably should merge this
    list.push(temp)
  })
}

export function resolveSavedFilters (type) {
  // TODO: move config here so that we can access the auth.user.

  const filters = config[type]
  return filters
}
